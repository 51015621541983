import { Title } from '../../../components'
import {
    Container
} from './styles'

import img4 from '../../../assets/abdomen/img4.png'
import img5 from '../../../assets/abdomen/img5.png'
import img6 from '../../../assets/abdomen/img6.png'
import img7 from '../../../assets/abdomen/img7.png'
import img8 from '../../../assets/abdomen/img8.png'
import img9 from '../../../assets/abdomen/img9.png'
import img10 from '../../../assets/abdomen/img10.png'


export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a coluna
lombar, utilizar os planos coronal e sagital.
                        </p>

                        <p>
                        Para filme, os cortes axiais (sem ou com contraste I.V.) devem ter início 1 corte acima do fígado
e término 2cm abaixo da bexiga urinaria.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal (sem ou com contraste I.V.) os cortes devem seguir a
orientação da posterior para anterior, utilizar os rins como referência para angulação, deixar os
cortes paralelos a eles, utilizar imagem no plano axial como referência. No plano sagital deixar os
cortes paralelos ao maior eixo do abdômen, utilizar imagem sagital como referência.
                        </p>
                        <p>
                        Para back-up os cortes coronais devem ter início antes da pele do dorso e término logo após a
pele da região ventral. 
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais filme devem ter início 1cm antes dos rins e término 1cm antes do
término do fígado.
                        </p>
                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
perpendiculares aos rins, utilizar imagem no plano axial como referência. 
                        </p>

                        <p>
                        Para back-up os cortes sagitais devem ter início antes da pele do flanco esquerdo e término logo
após o final da pele do flanco direito.
                        </p>


                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme devem ter início 1cm antes do rim esquerdo e término 1 cm após o rim direito.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais e multiplanares para complementar o estudo de acordo com a indicação.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p className="text-center">
                        Reformatações multiplanares curvas
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR é útil em demonstrar anatomia.</li>
                            <li>3D MIP é útil em intensificar determinadas partes da anatomia, principalmente vasos e orgãos contrastados. </li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}