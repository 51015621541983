import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-Up</Title>

                    <section className="mt-2 p-3">

                        <ul>
                            <li>Scout;</li>
                            <li>Axial P.M.;</li>
                            <li>Axial P.M. T1-T2;</li>
                            <li>Axial P.M. T2-T3;</li>
                            <li>Axial P.M. T3-T4;</li>
                            <li>Axial P.M. T4-T5;</li>
                            <li>Axial P.M. T5-T6;</li>
                            <li>Axial P.M. T6-T7;</li>
                            <li>Axial P.M. T7-T8;</li>
                            <li>Axial P.M. T8-T9;</li>
                            <li>Axial P.M. T9-T10;</li>
                            <li>Axial P.M. T10-T11;</li>
                            <li>Axial P.M. T11-T12;</li>
                            <li>Axial Osso;</li>
                            <li>Axial Osso T1-T2;</li>
                            <li>Axial Osso T2-T3;</li>
                            <li>Axial Osso T3-T4;</li>
                            <li>Axial Osso T4-T5;</li>
                            <li>Axial Osso T5-T6;</li>
                            <li>Axial Osso T6-T7;</li>
                            <li>Axial Osso T7-T8;</li>
                            <li>Axial Osso T8-T9;</li>
                            <li>Axial Osso T9-T10;</li>
                            <li>Axial Osso T10-T11;</li>
                            <li>Axial Osso T11-T12;</li>
                            <li>Sagital P.M.;</li>
                            <li>Sagital Osso</li>
                            <li>Coronal P.M.;</li>
                            <li>Coronal Osso;</li>
                            <li>3D VR Coluna Torácica Dir./Esq.</li>
                            <li>3D VR Coluna Torácica Sup./Inf.</li>
                            <li>Relatório de dose;</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}