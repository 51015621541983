import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento com Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                            Para os exames de adultos os valores entre 0,6 a 1,5 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 0,6 a 1 mm de espessura e espaçamento.<br/>
                            O FOV deve ser o menor possível se adequando a cada paciente.
                        </p>

                        <p className="text-center">
                            Reformatação da hipófise com contraste I.V. <br/>
                            Utilizar o Volume P.M. S/C (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal P.M. S/C</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. S/C</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p className="text-center">
                            Utilizar o Volume Osso e fazer as seguintes reformatações:  
                        </p>

                        <p>
                            <b>Coronal Osso</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p className="text-center">
                            Utilizar o Volume P.M. C/C Fase Arterial e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal C/C Fase Arterial</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Arterial</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p className="text-center">
                            Utilizar o Volume P.M. C/C Fase Venosa e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal C/C Fase Venosa</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Venosa</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal C/C Fase Venosa filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>12 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Venosa filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>12 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial C/C Fase Venosa filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}