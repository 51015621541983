import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/toraxAR/img2.png'
import img3 from '../../../assets/toraxAR/img3.png'
import img4 from '../../../assets/toraxAR/img4.png'
import img5 from '../../../assets/toraxAR/img5.png'
import img6 from '../../../assets/toraxAR/img6.png'
import img7 from '../../../assets/toraxAR/img7.png'
import img8 from '../../../assets/toraxAR/img8.png'
import img9 from '../../../assets/toraxAR/img9.png'
import img10 from '../../../assets/toraxAR/img10.png'
import img11 from '../../../assets/toraxAR/img11.png'
import img12 from '../../../assets/toraxAR/img12.png'


export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a coluna
torácica, utilizar os planos coronal e sagital.
                        </p>

                        <p>
                            Para filme, os cortes axiais mediastino devem ter início 1 corte acima do ápice pulmonar e término 1cm abaixo do final do pulmão.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes axiais pulmão devem ter início 1 corte acima do ápice pulmonar e término 1cm abaixo do final do pulmão.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior,
deixar os cortes paralelos a face posterior dos pulmões, utilizar imagem no plano axial como
referência. No plano sagital deixar os cortes paralelos a coluna torácica, utilizar imagem sagital
como referência.
                        </p>

                        <p>
                        Para back-up, os cortes coronais mediastino devem ter início antes da pele do dorso e término
logo após região anterior do tórax. 
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes coronais pulmão devem ter início antes da pele do dorso e término logo após região anterior do tórax. 
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes coronais mediastino filme devem ter início antes da face posterior do pulmão e término logo após a face anterior do pulmão. 
                        </p>
                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais pulmão filme devem ter início 2cm após o início da face posterior dos pulmões e término 2cm antes da face anterior dos pulmões.
                        </p>

                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar imagem no plano axial como referência. 
                        </p>

                        <p>
                            Para back-up, os cortes sagitais mediastino devem ter início antes da pele da face lateral esquerda do tórax e término logo após o final da pele da face lateral direita do tórax.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes sagitais pulmão devem ter início antes da pele da face lateral esquerda do tórax e término logo após o final da pele da face lateral direita do tórax.
                        </p>

                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais pulmão devem ter início 2cm após a face lateral do pulmão esquerdo e término 2cm antes do final da face lateral do pulmão direito.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais mediastino devem ter início 2cm após a face lateral do pulmão esquerdo e término 2cm antes do final da face lateral do pulmão direito.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p className="text-center font-weight-bold">
                            Abaixo algumas opções de reformatações tridimensionais e multiplanares para complementar o estudo de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />
                        
                        <ul>
                            <li>3D VR é útil em demonstrar anatomia. </li>
                            <li>3D MIP é útil em demonstrar nódulos pulmonares. </li>
                            <li>3D Min IP é útil em demonstrar aprisionamento de ar.</li>
                        </ul>

                        


                    </section>
                </div>
            </div>
        </Container>
    )
}