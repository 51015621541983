import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/cotovelo/img2.PNG'
import img3 from '../../../assets/cotovelo/img3.png'
import img4 from '../../../assets/cotovelo/img4.png'
import img5 from '../../../assets/cotovelo/img5.png'
import img6 from '../../../assets/cotovelo/img6.png'
import img7 from '../../../assets/cotovelo/img7.png'
import img8 from '../../../assets/cotovelo/img8.png'
import img9 from '../../../assets/cotovelo/img9.png'
import img10 from '../../../assets/cotovelo/img10.png'
import img11 from '../../../assets/cotovelo/img11.png'
import img12 from '../../../assets/cotovelo/img12.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares ao rádio
e ulna, utilizar o plano coronal e sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início 2 cm acima da
fossa do olecrano e término 1 cm abaixo da tuberosidade do rádio.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início 2 cm acima da fossa do olecrano e término 1
cm abaixo da tuberosidade do rádio.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os epicôndilos como referência para angulação, deixar os cortes paralelos ao
seu maior eixo, utilizar imagem no plano axial como referência. No plano sagital deixar os cortes
paralelos ao úmero, utilizar imagem sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior e término após o a musculatura anterior do cotovelo.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior e término
após o a musculatura anterior do cotovelo.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início no início da
musculatura posterior e término no final da musculatura anterior do cotovelo.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início antes da ulna e término logo após
rádio e ulna.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar a porção distal do úmero (epicôndilos) como referência para angulação, utilizar imagem no
plano axial como referência. No plano coronal deixar os cortes paralelos ao rádio e ulna, utilizar
imagem coronal como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do cotovelo direito devem ter início
antes da musculatura medial e término após a musculatura lateral do cotovelo e do cotovelo
esquerdo, o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso do braço do cotovelo direito devem ter início antes da
musculatura medial e término após a musculatura lateral do cotovelo e do cotovelo esquerdo, o
contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) do cotovelo direito devem ter
início na musculatura medial e término na musculatura lateral do cotovelo e do cotovelo esquerdo,
o contrário.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso do cotovelo direito devem ter início antes do epicôndilo medial
e término logo após o lateral e do cotovelo esquerdo, o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do
Cotovelo de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />



                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}