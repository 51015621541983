import { useHistory } from 'react-router-dom'
import { Title } from '../../../../components'
import {
    Container
} from './styles'

export default function (){
    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento são bem
aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2mm de espessura
e espaçamento.
                        </p>
                        <p>
                        O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 36 a 42cm os mais habituais. 
                        </p>
                        <p>
                        As reformatações curvas tem grande valor informativo, sendo feitas como rotina em muitos serviços quando houver calculo uretral. 
                        </p>

                        <p className="text-center font-weight-bold">
                        Reformatação da Urotomografia com contraste I.V.
                        </p>

                        <p className="text-center">
                        Utilizar o Volume S/C e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial  S/C Filme</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>48 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal S/C</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>


                        <p>
                            <b>Sagital S/C</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>Espaçamento: </b>2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Cortical e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal C/C Fase Cortical</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>48 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Cortical</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Medular e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal C/C Fase Medular</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>48 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Medular</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>


                        <p>
                            <b>Axial C/C filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>48 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>
                        <p>
                            <b>Coronal C/C filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Fase Excretora e fazer as seguintes reformatações
                        </p>

                        <p>
                            <b>Coronal C/C Fase Excretora</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Excretora</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>


                        <p>
                            <b>Coronal C/C Filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>



                        <ul>
                            <li>Fazer 12 imagens em 3D Volume Redering dos rins, ureteres e bexiga girando da direita para esquerda, salvar como 3D VR - URO Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D Volume Redering dos rins, ureteres e bexiga girando da direita para esquerda, salvar como 3D VR - URO Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D MIP dos rins, ureteres e bexiga girando da superior para inferior, salvar como 3D MIP - URO Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D MIP dos rins, ureteres e bexiga girando da superior para inferior, salvar como 3D MIP- URO Sup. / Inf.</li>
                            <li>Fazer reformatação curva sagital e coronal dos ureteres direito e esquerdo.</li>
                        </ul>
                        
                    </section>
                </div>
            </div>
        </Container>
    )
}