import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/colunaToracica/img2.png'
import img3 from '../../../assets/colunaToracica/img3.png'
import img4 from '../../../assets/colunaToracica/img4.png'
import img5 from '../../../assets/colunaToracica/img5.png'
import img6 from '../../../assets/colunaToracica/img6.png'
import img7 from '../../../assets/colunaToracica/img7.png'
import img8 from '../../../assets/colunaToracica/img8.png'
import img9 from '../../../assets/colunaToracica/img9.png'
import img10 from '../../../assets/colunaToracica/img10.png'
import img11 from '../../../assets/colunaToracica/img11.png'
import img12 from '../../../assets/colunaToracica/img12.png'
import img13 from '../../../assets/colunaToracica/img13.png'
import img14 from '../../../assets/colunaToracica/img14.png'
import img15 from '../../../assets/colunaToracica/img15.png'
import img16 from '../../../assets/colunaToracica/img16.png'
import img17 from '../../../assets/colunaToracica/img17.png'
import img18 from '../../../assets/colunaToracica/img18.png'
import img19 from '../../../assets/colunaToracica/img19.png'
import img20 from '../../../assets/colunaToracica/img20.png'
import img21 from '../../../assets/colunaToracica/img21.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
utilizar o disco intervertebral como referência para angulação para avaliação dos discos
intervertebrais ou deixar perpendicular a coluna lombar em casos de trauma ou tumores,
selecionar imagem no plano sagital como referência. Caso a coluna esteja girada, corrigir no
plano coronal.<br/>
Para back-up e filme, os cortes axiais P.M. (sem ou com contraste I.V.) para avaliação dos
discos intervertebrais devem ter início logo acima do forame intervertebral e término logo após o
final do forame intervertebral de modo que o primeiro e o último corte mostrem os pedículos
vertebrais bilateralmente conforme ilustração abaixo.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Fazer as reformatações nos espaços Intervertebrais de T1 a T12. Para facilitar a identificação,
nomear as séries de acordo com o espaço intervertebral, tipo de filtro utilizado e meio de
contraste I.V. quando utilizado.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />
                        <img className="img-fluid" src={img4} alt="Reformatacoes" />
                        <img className="img-fluid" src={img5} alt="Reformatacoes" />
                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para back-up e filme, os cortes axiais Osso para avaliação dos discos intervertebrais devem ter
início logo acima do forame intervertebral e término logo após o final do forame intervertebral de
modo que o primeiro e o último corte mostrem os pedículos vertebrais bilateralmente.<br/>
Fazer as reformatações nos espaços Intervertebrais de T1 a T12. Para facilitar a identificação,
nomear as séries de acordo com o espaço discal, tipo de filtro utilizado.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />
                        <img className="img-fluid" src={img8} alt="Reformatacoes" />
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />
                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais P.M. (sem ou com contraste I.V.) para avaliação de trauma ou
tumor devem ter início logo acima do corpo vertebral de T1 e término logo após o final de T12.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso para avaliação de trauma ou tumor devem ter início logo
acima do corpo vertebral de T1 e término logo após o final de T12.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar imagem no plano sagital como referência, deixar os cortes perpendiculares a
coluna torácica. Caso a esta esteja girada corrigir no plano axial.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da pele
e término 1 cm anterior a coluna torácica.
                        </p>

                        <img className="img-fluid" src={img13} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da pele e término 1 cm anterior a
coluna torácica.
                        </p>
                
                        <img className="img-fluid" src={img14} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. (sem e com contraste I.V) devem ter início no processo
espinhoso e término no início da coluna torácica.
                        </p>

                        <img className="img-fluid" src={img15} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso devem ter início no processo espinhoso e término no início
da coluna torácica.
                        </p>

                        <img className="img-fluid" src={img16} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar a coluna torácica como referência para angulação, utilizar imagem no plano coronal como
referência. Caso a coluna esteja girada corrigir no plano axial.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início antes do
processo transverso esquerdo e término logo após o final do processo transverso direito.
                        </p>

                        <img className="img-fluid" src={img17} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso devem ter início antes do processo transverso esquerdo e
término logo após o final do processo transverso direito.
                        </p>

                        <img className="img-fluid" src={img18} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes sagitais P.M. (sem ou com contraste I.V) devem ter início antes da cabeça
da costela esquerda e término logo após a cabeça da costela direita.
                        </p>

                        <img className="img-fluid" src={img19} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes sagitais osso devem ter início antes da cabeça da costela esquerda e término
logo após a cabeça da costela direita.
                        </p>

                        <img className="img-fluid" src={img20} alt="Reformatacoes" />

                        <p className="text-center">
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da coluna lombar de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img21} alt="Reformatacoes" />


                        
                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}