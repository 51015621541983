import {
    Container
} from './styles'

import {
    Title
} from '../../components'
import { useHistory } from 'react-router-dom'

export default function MembrosInferiores(){

    const items = [
        {
            text: "Tomografia Computadorizada Multislice da Coluna Cervical",
            url: "/colunaCervical"
        },
        {
            text: "Tomografia Computadorizada Multislice da Coluna Torácica",
            url: "/colunaToracica"
        },
        {
            text: "Tomografia Computadorizada Multislice da Coluna Lombar",
            url: "/colunaLombar"
        }
    ]

    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Coluna Vertebral</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}