import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/MIJoelho/img2.png'
import img3 from '../../../assets/MIJoelho/img3.png'
import img4 from '../../../assets/MIJoelho/img4.png'
import img5 from '../../../assets/MIJoelho/img5.png'
import img6 from '../../../assets/MIJoelho/img6.png'
import img7 from '../../../assets/MIJoelho/img7.png'
import img8 from '../../../assets/MIJoelho/img8.png'
import img9 from '../../../assets/MIJoelho/img9.png'
import img10 from '../../../assets/MIJoelho/img10.png'
import img11 from '../../../assets/MIJoelho/img11.png'
import img12 from '../../../assets/MIJoelho/img12.png'
import img13 from '../../../assets/MIJoelho/img13.png'
import img14 from '../../../assets/MIJoelho/img14.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano sagital como referência. Deixar os cortes paralelos ao platô tibial,
utilizar o plano coronal.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima da base
da patela e término 1 cm abaixo da tuberosidade anterior da tíbia.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima da base da patela e término 1 cm
abaixo da tuberosidade anterior da tíbia.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os côndilos femorais como referência para angulação, deixar os cortes paralelos
a eles, utilizar imagem no plano axial como referência. No plano sagital deixar os cortes
paralelos ao fêmur / tíbia, utilizar imagem sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior da articulação do joelho e término logo após a patela.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior da
articulação do joelho e término logo após a patela.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 0.5 cm
antes dos côndilos posteriores do fêmur e término 0,5 cm após o fêmur.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início 0.5 cm antes dos côndilos femorais e
término 0,5 cm após o fêmur.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar os côndilos femorais como referência para angulação, selecionar imagem no plano axial
<br/>
como referência, deixar os cortes perpendiculares aos côndilos femorais. No plano coronal deixar
os cortes perpendiculares ao platô tibial, utilizar imagem coronal como referência.
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do joelho direito devem ter início
2cm do epicôndilo medial e término 2cm do epicôndilo lateral. O joelho esquerdo os cortes devem
ter início 2cm do epicôndilo lateral e término 2cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso do joelho direito devem ter início 2cm do epicôndilo medial
e término 2cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 2cm do
epicôndilo lateral e término 2cm do epicôndilo medial.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. (sem e com contraste I.V.) do joelho direito devem ter início
0.5 cm do epicôndilo medial e término 0.5 cm do epicôndilo lateral. O joelho esquerdo os cortes
devem ter início 0.5 cm do epicôndilo lateral e término 0.5 cm do epicôndilo medial.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso do joelho direito devem ter início 0.5 cm do epicôndilo medial
e término 0.5 cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 0.5 cm do
epicôndilo lateral e término 0.5 cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        

                        <p>
                        Para back-up os cortes sagitais P.M. L.C.A. do joelho direito devem ter início 1cm médio lateral
do L.C.A. e término 1cm latero medial do L.C.A.. O joelho esquerdo os cortes devem ter início
1cm latero medial do L.C.A. e término 1cm médio lateral do L.C.A.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do Joelho de acordo com a indicação.</p>
                        <img className="img-fluid" src={img13} alt="Reformatacoes" />
                        <img className="img-fluid" src={img14} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}