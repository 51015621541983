import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento Sem Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                            Utilizar o Volume P.M. boca aberta e fechda (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <ul>
                            <li>Fazer 12 imagens em 3D Volume Redering das ATMs girando da direita para esquerda, salvar como 3D ATMs Boca fechada Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D Volume Redering das ATMs girando da superior para inferior, salvar como 3D ATMs Boca fechada Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D Volume Redering das ATMs girando da direita para esquerda, salvar como 3D ATMs Boca aberta Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D Volume Redering das ATMs girando da superior para inferior, salvar como 3D ATMs Boca aberta Sup. / Inf.</li>
                        </ul>

                        <p>
                            Utilizar o Volume P.M. boca fechada (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial P.M. Boca fechada Bilateral filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca fechada ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca fechada ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca fechada ATM direita filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca fechada ATM esquerda filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca fechada ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca fechada ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            Utilizar o Volume P.M. boca aberta (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Sagital P.M. Boca aberta ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca aberta ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca aberta ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca aberta ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. Boca aberta ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca aberta ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            Utilizar o Volume Osso boca fechada (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial Osso Boca fechada Bilateral filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca fechada ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca fechada ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca fechada ATM direita filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca fechada ATM esquerda filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Boca fechada ATM direita filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca fechada ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Boca fechada ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Boca fechada ATM esquerda filme </b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            Utilizar o Volume Osso boca aberta (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Sagital Osso Boca aberta ATM direita</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca aberta ATM esquerda</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca aberta ATM direita filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso Boca aberta ATM esquerda filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}