import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/cranio/img2.PNG'
import img3 from '../../../assets/cranio/img3.PNG'
import img4 from '../../../assets/cranio/img4.PNG'
import img5 from '../../../assets/cranio/img5.PNG'
import img6 from '../../../assets/cranio/img6.PNG'
import img7 from '../../../assets/cranio/img7.PNG'
import img8 from '../../../assets/cranio/img8.PNG'
import img9 from '../../../assets/cranio/img9.PNG'
import img10 from '../../../assets/cranio/img10.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, utilizar a linha orbitomeatal como referência para angulação, selecionar imagem no plano sagital como referência. Caso o crânio esteja girado, corrigir no plano coronal.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início na altura do forame magno e término no final do parênquima encefálico.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes axiais Osso devem ter início na altura do forame magno e término no final da calota craniana.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar imagem no plano sagital como referência.  Utilizar o tronco encefálico como referência para angulação, caso o crânio esteja girado corrigir no plano axial.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V.) devem ter início antes do osso occipital e término logo após o osso frontal.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes do osso occipital e término logo após o osso frontal.
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme os cortes devem ter início a 2cm da porção posterior do lobo occipital e término a 2cm da porção anterior do lobo frontal. 
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar a linha média como referência para angulação, utilizar imagem no plano axial como referência. Caso o crânio esteja girado corrigir no plano coronal.<br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início antes do osso temporal esquerdo e término logo após o final do osso temporal direito.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso devem ter início antes do osso temporal esquerdo e término logo após o final do osso temporal direito.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para filme os cortes devem ter início a 2cm da margem lateral do lobo parietal esquerdo e término a 2cm da margem lateral do lobo parietal direito. 
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do crânio de acordo com a indicação.  
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <ul>
                            <li>3D encéfalo é útil para demonstrar anatomia, alterações dos giros e doenças da cortical cerebral.</li>
                            <li>3D VR Partes Moles tem valor em demonstrar deformidade, assimetria e patologias nas partes moles.</li>
                            <li>3D MIP e VR Ósseo é útil em demonstrar anatomia óssea e fraturas. </li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}