import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/antePe/img2.png'
import img3 from '../../../assets/antePe/img3.png'
import img4 from '../../../assets/antePe/img4.png'
import img5 from '../../../assets/antePe/img5.png'
import img6 from '../../../assets/antePe/img6.png'
import img7 from '../../../assets/antePe/img7.png'
import img8 from '../../../assets/antePe/img8.png'
import img9 from '../../../assets/antePe/img9.png'
import img10 from '../../../assets/antePe/img10.png'
import img11 from '../../../assets/antePe/img11.png'
import img12 from '../../../assets/antePe/img12.png'
import img13 from '../../../assets/antePe/img13.png'
import img14 from '../../../assets/antePe/img14.png'
import img15 from '../../../assets/antePe/img15.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial oblíquo os cortes devem seguir a orientação da superior para
inferior, selecionar imagem no plano coronal como referência, deixar os cortes paralelos aos
metatarsos. No plano sagital, deixar os cortes perpendiculares aos metatarsos.<br/>
Para back-up, os cortes axiais P.M. oblíquos (sem e com contraste I.V.) devem ter início logo
acima do dorso do pé e término logo abaixo da face plantar do pé.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso oblíquos devem ter início logo acima do dorso do pé e término
logo abaixo da face plantar do pé.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais P.M. oblíquos (sem e com contraste I.V.) devem ter início logo
acima dos ossos do tarso e término logo abaixo da musculatura do ante pé.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima dos ossos do tarso e término logo
abaixo da musculatura do ante pé.
                        </p>

                        <p className="text-center">
                        Imagem Axial Osso filme               Ref. Coronal                Ref. Sagital
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os ossos do carpo como referência para angulação, deixar os cortes
perpendiculares a eles, utilizar imagem no plano sagital como referência. No plano axial deixar
os cortes perpendiculares aos metatarsos, utilizar imagem axial referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início no começo
dos ossos do tarso e término logo após o as falanges distais.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início no começo dos ossos do tarso e término
logo após o as falanges distais.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início no terço
médio dos ossos do tarso e término logo após o as falanges proximais.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início no terço médio dos ossos do tarso e
término logo após o as falanges proximais.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar os ossos do tarso como referência para angulação, utilizar imagem no plano axial oblíqua
como referência deixando os cortes paralelos ao metatarso de interesse. No plano coronal deixar
os cortes perpendiculares aos metatarsos, utilizar imagem coronal como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do ante pé direito devem ter
início antes do início quinto dedo e término logo após o final do primeiro dedo e do ante pé
esquerdo, o contrário.
                        </p>

                        <p className="text-center">
                        Imagem Sagital P.M.                Ref. Axial                 Ref. Coronal
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso do ante pé direito devem ter início antes do início quinto
dedo e término logo após o final do primeiro dedo e do ante pé esquerdo, o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) do ante pé direito devem ter
início no início quinto dedo e término no final do primeiro dedo e do ante pé esquerdo, o contrário.
                        </p>

                        <p className="text-center">
                        Imagem Sagital P.M.             Ref. Axial              Ref. Coronal
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>Para filme, os cortes sagitais Osso filme do ante pé direito devem ter início no início quinto dedo
e término no final do primeiro dedo e do ante pé esquerdo, o contrário.</p>

                        <img className="img-fluid" src={img13} alt="Reformatacoes" />
                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do

ombro de acordo com a indicação.
                        </p>

                        <p className="text-center">
                            3D MIP (parte óssea)
                        </p>

                        <img className="img-fluid" src={img14} alt="Reformatacoes" />
                        <img className="img-fluid" src={img15} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas. </li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}