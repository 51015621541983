import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Realização e Programação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Scout: </b>Scout AP (0 grau)<br/>
                            <b>mA: </b>10<br/>
                            <b>Início: </b>S150<br />
                            <b>Fim: </b>I450
                        </p>

                        <p>
                            <b>Scout: </b>Scout Perfil (90 graus) <br/>
                            <b>mA: </b>20<br/>
                            <b>Início: </b>S150<br />
                            <b>Fim: </b>I450
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}