import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/perna/img2.png'
import img3 from '../../../assets/perna/img3.png'
import img4 from '../../../assets/perna/img4.png'
import img5 from '../../../assets/perna/img5.png'
import img6 from '../../../assets/perna/img6.png'
import img7 from '../../../assets/perna/img7.png'
import img8 from '../../../assets/perna/img8.png'
import img9 from '../../../assets/perna/img9.png'
import img10 from '../../../assets/perna/img10.png'
import img11 from '../../../assets/perna/img11.png'
import img12 from '../../../assets/perna/img12.png'
import img13 from '../../../assets/perna/img13.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a tíbia,
utilizar o plano sagital e coronal.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima do platô
tibial e término ao fim do maléolo lateral da fíbula.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima do platô tibial e término ao fim do
maléolo lateral da fíbula.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar a tíbia como referência para angulação, deixar os cortes paralelos a ela, utilizar
imagem no plano sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior e término logo após o a musculatura anterior da perna.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior e término
logo após o a musculatura anterior da perna.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início na
musculatura posterior e término logo após a tíbia.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso devem ter início na musculatura posterior e término logo
após a tíbia.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar tíbia como referência para angulação, utilizar imagem no plano coronal como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) da perna direita devem ter início
antes da musculatura medial da perna e término logo após o final da musculatura lateral e da
perna esquerda, o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso da perna direita devem ter início antes da musculatura
medial da perna e término logo após o final da musculatura lateral e da perna esquerda, o
contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) da perna direita devem ter
início na musculatura medial da perna e término na musculatura lateral e da perna esquerda, o
contrário.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso filme da perna direita devem ter início na musculatura medial
da perna e término na musculatura lateral e da perna esquerda, o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da

perna de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />


                        <img className="img-fluid" src={img13} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}