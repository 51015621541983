import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/face/img2.PNG'
import img3 from '../../../assets/face/img3.PNG'
import img4 from '../../../assets/face/img4.PNG'
import img5 from '../../../assets/face/img5.PNG'
import img6 from '../../../assets/face/img6.PNG'
import img7 from '../../../assets/face/img7.PNG'
import img8 from '../../../assets/face/img8.PNG'
import img9 from '../../../assets/face/img9.PNG'
import img10 from '../../../assets/face/img10.PNG'
import img11 from '../../../assets/face/img11.PNG'
import img12 from '../../../assets/face/img12.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, selecionar imagem no plano coronal como referência deixando os cortes perpendiculares ao septo nasal. Deixar os cortes paralelos ao palato ósseo, utilizar o plano sagital.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo abaixo da protuberância mentual e termino logo acima do seio frontal.  
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes axiais Osso devem ter início logo abaixo da protuberância mentual e termino logo acima do seio frontal. 
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar o septo nasal como referência para angulação, deixar os cortes perpendicular a ele, utilizar imagem no plano axial como referência.  No plano sagital deixar os cortes perpendiculares ao palato ósseo, utilizar imagem sagital como referência.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início 2cm antes dos côndilos mandibulares e término logo após o ápice do nariz.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes coronais Osso devem ter início 2cm antes dos côndilos mandibulares e término logo após o ápice do nariz.  
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início nos côndilos mandibulares e término logo após o seio frontal.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais Osso filme devem ter início nos côndilos mandibulares e término logo após o seio frontal.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar o septo nasal ósseo como referência para angulação deixando os cortes paralelos a ele, utilizar imagem no plano axial como referência. No plano coronal deixar os cortes perpendiculares aos côndilos mandibulares, utilizar imagem coronal como referência.<br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início antes da glândula parótida esquerda e término logo após o final da glândula parótida direita.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso devem ter início antes da glândula parótida esquerda e término logo após o final da glândula parótida direita.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) devem ter início no arco zigomático esquerdo e término logo após o final do arco zigomático direito.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais Osso filme devem ter início no arco zigomático esquerdo e término logo após o final do arco zigomático direito.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da face de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo  e P.M. é útil em demonstrar anatomia óssea e fraturas. </li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}