import {
    Container
} from './styles'

import {
    Title
} from '../../components'
import { useHistory } from 'react-router-dom'

export default function MembrosInferiores(){

    const items = [
        {
            text: "Tomografia Computadorizada Multislice do Crânio",
            url: "/cranio"
        },
        {
            text: "Tomografia Computadorizada Multislice das Orbitas",
            url: "/orbitas"
        },
        {
            text: "Tomografia Computadorizada Multislice dos Seios da Face",
            url: "/seiosFace"
        },
        {
            text: "Tomografia Computadorizada Multislice da Face",
            url: "/face"
        },
        {
            text: "Tomografia Computadorizada Multislice da Hipófise",
            url: "/hipofise"
        },
        {
            text: "Tomografia Computadorizada Multislice das mastoides/ouvidos",
            url: "/ouvidos"
        },
        {
            text: "Tomografia Computadorizada da ATM (articulação temporomandibular)",
            url: "/atm"
        },
        {
            text: "Tomografia Computadorizada Multislice do Pescoço",
            url: "/pescoco"
        },
    ]

    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Cabeça e Pescoço</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}