import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Contato</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col text-center">
                    <p>
                        Universidade do Vale do Sapucaí – UNIVÁS<br/>
                        Contato: proppes_ss@univas.edu.br
                    </p>
                    <p>
                        Roger Passos Pereira<br/>
                        Contato: rpassosp@hotmail.com
                    </p>
                </div>
            </div>
        </Container>
    )
}