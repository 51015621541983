import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/tornozelo/img2.png'
import img3 from '../../../assets/tornozelo/img3.png'
import img4 from '../../../assets/tornozelo/img4.png'
import img5 from '../../../assets/tornozelo/img5.png'
import img6 from '../../../assets/tornozelo/img6.png'
import img7 from '../../../assets/tornozelo/img7.png'
import img8 from '../../../assets/tornozelo/img8.png'
import img9 from '../../../assets/tornozelo/img9.png'
import img10 from '../../../assets/tornozelo/img10.png'
import img11 from '../../../assets/tornozelo/img11.png'
import img12 from '../../../assets/tornozelo/img12.png'
import img13 from '../../../assets/tornozelo/img13.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a
articulação tibiotalar, utilizar o plano coronal e sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início 3 cm acima da
articulação tibiotalar e término logo abaixo do calcâneo.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início 3 cm acima da articulação tibiotalar e término
logo abaixo do calcâneo.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar o maléolo lateral da fíbula e o maléolo medial da tíbia como referência para
angulação, deixar os cortes paralelos a eles, utilizar imagem no plano axial como referência. No
plano sagital deixar os cortes paralelos a tíbia, utilizar imagem sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes do
tendão do calcâneo e término logo após os ossos do tarso.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes do tendão do calcâneo e término
logo após os ossos do tarso.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 1cm após
o calcâneo e término 1cm após o início dos ossos do tarso.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início 1cm após o calcâneo e término 1cm
após o início dos ossos do tarso.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar o maléolo lateral da fíbula e o maléolo medial da tíbia como referência para angulação,
deixar os cortes perpendiculares a eles, utilizar imagem no plano axial como referência. No plano
sagital deixar os cortes paralelos a tíbia e fíbula, utilizar imagem coronal como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do tornozelo direito devem ter
início 1cm antes do maléolo medial da tíbia e término 1cm após o maléolo lateral da fíbula e do
tornozelo esquerdo, o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso do tornozelo direito devem ter início 1cm antes do maléolo
medial da tíbia e término 1cm após o maléolo lateral da fíbula e do tornozelo esquerdo, o
contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) do tornozelo direito devem
ter início no maléolo medial da tíbia e término no maléolo lateral da fíbula e do tornozelo esquerdo,
o contrário.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso filme do tornozelo direito devem ter início no maléolo medial
da tíbia e término no maléolo lateral da fíbula e do tornozelo esquerdo, o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do

tornozelo de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />


                        <img className="img-fluid" src={img13} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}