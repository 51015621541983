import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Limites para aquisição das imagens com contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        A programação da fase medular e excretora são idênticas a fase sem contraste I.V. e na fase
cortical apenas o final da programação que difere, tendo como limite inferior as cristas ilíacas
conforme ilustração abaixo.
                        </p>

                        
                    </section>
                </div>
            </div>
        </Container>
    )
}