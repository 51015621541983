import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/orbitas/img2.PNG'
import img3 from '../../../assets/orbitas/img3.PNG'
import img4 from '../../../assets/orbitas/img4.PNG'
import img5 from '../../../assets/orbitas/img5.PNG'
import img6 from '../../../assets/orbitas/img6.PNG'
import img7 from '../../../assets/orbitas/img7.PNG'
import img8 from '../../../assets/orbitas/img8.PNG'
import img9 from '../../../assets/orbitas/img9.PNG'
import img10 from '../../../assets/orbitas/img10.PNG'
import img11 from '../../../assets/orbitas/img11.PNG'
import img12 from '../../../assets/orbitas/img12.PNG'
import img13 from '../../../assets/orbitas/img13.PNG'
import img14 from '../../../assets/orbitas/img14.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, selecionar imagem no plano coronal como referência deixando os cortes paralelos ao teto das orbitas. No plano sagital deixar os cortes perpendiculares ao nervo óptico.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início 1cm acima do teto das orbitas e término 1 cm abaixo da base das orbitas.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes axiais Osso devem ter início 1cm acima do teto das orbitas e término 1 cm abaixo da base das orbitas.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar o bulbo ocular como referência para angulação, deixar os cortes paralelos a eles, utilizar imagem no plano axial como referência.  No plano sagital deixar os cortes perpendiculares ao nervo óptico, utilizar imagem sagital como referência.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início 2cm antes da sela turca e término 1cm após os bulbos oculares. 
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início 2cm antes da sela turca e término 1cm após os bulbos oculares. 
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início na sela turca e término no final dos bulbos oculares.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais Osso filme devem ter início na sela turca e término no final dos bulbos oculares.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar o nervo óptico como referência para angulação, utilizar imagem no plano axial como referência. No plano coronal deixar os cortes perpendiculares ao teto das orbitas, utilizar imagem coronal como referência.<br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) da orbita direita devem ter início 1cm antes da margem medial do bulbo ocular direito e término 1cm após o final da margem lateral do bulbo ocular direito.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) da orbita esquerda devem ter início 1cm antes da margem lateral do bulbo ocular esquerdo e término 1cm após o final da margem medial do bulbo ocular esquerdo.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso da orbita direita devem ter início 1cm antes da margem medial do bulbo ocular direito e término 1cm após o final da margem lateral do bulbo ocular direito.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso da orbita esquerda devem ter início 1cm antes da margem lateral do bulbo ocular esquerdo e término 1cm após o final da margem medial do bulbo ocular esquerdo.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) da orbita direita devem ter início na margem medial do bulbo ocular direito e término após o final da margem lateral do bulbo ocular direito.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. (sem ou com contraste I.V.) filme da orbita esquerda devem ter início na margem lateral do bulbo ocular esquerdo e término após o final da margem medial do bulbo ocular esquerdo.
                        </p>

                        <img className="img-fluid" src={img13} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais para complementar o estudo das orbitas de acordo com a indicação.
                        </p>

                        <p className="text-center">
                            3D VR Ósseo
                        </p>

                        <img className="img-fluid" src={img14} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas. </li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}