import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Autores</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>
                        <b>Roger Passos Pereira</b><br/>
                        - Mestrando em Ciências Aplicadas à Saúde<br/>
                        - Especialização em Anatomia Macroscópica e Recursos Técnicos Laboratoriais Aplicados a Morfologia<br/>
                        - Especialização em Tomografia Computadorizada e Ressonância Magnética<br/>
                        - Tecnólogo em Radiologia<br/>
                    </p>
                    <p>
                        <b>Geraldo Magela Salomé</b><br/>
                        - Pós- Doutorado em Cirurgia Plástica e Restauradora<br/>
                        - Doutorado em Cirurgia Plástica<br/>
                        - Mestrado em Enfermagem<br/>
                        - Especialização em Estomoterapia<br/>
                        - Graduação em Enfermagem e Obstetrícia<br/>
                        - Docente do Mestrado Profissional em Ciências Aplicadas à Saúde<br/>
                    </p>
                    <p>
                        <b>José Dias da Silva Neto</b><br/>
                        - Doutorado em Ciências da Saúde<br/>
                        - Mestrado em Endodontia<br/>
                        - Especialização em Endodontia<br/>
                        - Graduação em Odontologia<br/>
                        - DOcente do Mestrado Profissional em Ciências Aplicadas à Saúde<br/>
                        - Pró-Reitor de Pós-Graduação e Pesquisa da Universidade do Vale do Sapucaí - UNIVÁS<br/>
                        - Coordenador-adjunto do Mestrado Profissional em Ciências Aplicadas à Saúde da UNIVÁS<br/>
                        - Coordenador e professor dos cursos de Especialização em Endodontia Lato Sensu da UNIVÁS<br/>
                    </p>

                    <p>
                        <b>Augusto Castelli von Atzingen</b><br/>
                        - Doutorado em Radiologia Clínica<br/>
                        - Mestrado em Bioética<br/>
                        - Especialização – Residência Médica em Radiologia e Diagnóstico por Imagem<br/>
                        - Especialização – Residência Médica em Ginecologia e Obstetrícia<br/>
                        - Graduação em Medicina<br/>
                        - Docente do Mestrado em Educação, Conhecimento e Sociedade<br/>
                    </p>
                </div>
            </div>
        </Container>
    )
}