import { useHistory } from 'react-router-dom'
import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    const history = useHistory()
    const items = [
        {
            text: "Parâmetros Técnicos",
            url: "/toraxAR/parametrosTecnicos"
        },
        {
            text: "Posição do Paciente",
            url: "/toraxAR/posicaoPaciente"
        },
        {
            text: "Programação",
            url: "/toraxAR/linhasReferencia"
        },
        {
            text: "Reformatações Multiplanares",
            url: "/toraxAR/reformatacoesMultiplanares"
        },
        {
            text: "Protocolos de Pós-processamento",
            url: "/toraxAR/reformatacoes"
        },
        {
            text: "Back-Up",
            url: "/toraxAR/Reformatacoes/BackUp"
        },
        {
            text: "Documentação",
            url: "/toraxAR/reformatacoes/Documentacao"
        },
    ]
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Tomografia Computadorizada Multislice do Tórax em Alta Resolução</Title>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`/tronco${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}