import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Parâmetros técnicos para aparelho de 16 detectores:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Linhas do detector: </b>16<br/>
                            <b>Espes. Helicoidal(mm): </b>1.125<br/>
                            <b>Modo scan: </b>1.375:1<br />
                            <b>Vel. (mm/rot): </b>13.75
                        </p>
                        <p>
                            <b>Tipo de scan: </b>Helicoidal<br/>
                            <b>Tempo de rotação: </b>0.8 segundo<br/>
                            <b>Comprimento de rotação: </b>Total
                        </p>
                        <p>
                            <b>kV: </b>120<br/>
                            <b>Auto - mA: </b>Mín.100 a Máx.300<br/>
                            <b>Scan FOV: </b>Large<br/>
                            <b>Índice de ruído: </b>4
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}