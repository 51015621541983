import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento Sem Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                        Para os exames de adultos os valores entre 1.5 a 3 mm de espessura e espaçamento são bem
aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1.5 a 2.5 mm de
espessura e espaçamento.<br/>
O FOV deve ser o menor possível se adequando a cada paciente.<br/>
As reformatações tridimensionais tem grande valor informativo, sendo feitas como rotina em
muitos serviços.
                        </p>
                        

                        <p className="text-center">
                        Reformatação da Perna sem contraste I.V.<br/>
                        Utilizar o Volume P.M. (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <ul>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering da perna girando da direita para esquerda, salvar como 3D VR Perna Dir. /Esq.</li>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering da perna girando da superior para inferior, salvar como 3D VR Perna Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo da perna girando da direita para esquerda, salvar como 3D MIP Perna Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo da perna girando da superior para inferior, salvar como 3D MIP Perna Sup. / Inf.</li>
                        </ul>

                        <p>
                            <b>Coronal P.M.</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M.</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial P.M. Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>40 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Osso e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Sagital Osso</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Coronal Osso </b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial Osso Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>40 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Coronal Osso Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}