import { Title } from '../../../components'
import {
    Container
} from './styles'

import img3 from '../../../assets/rins/img3.png'
import img4 from '../../../assets/rins/img4.png'
import img5 from '../../../assets/rins/img5.png'
import img6 from '../../../assets/rins/img6.png'
import img7 from '../../../assets/rins/img7.png'
import img8 from '../../../assets/rins/img8.png'


export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a coluna
lombar, utilizar os planos coronal e sagital.
                        </p>

                        <p>
                            Para filme, os cortes axiais devem ter início 1 corte acima do fígado e término 2cm abaixo da bexiga urinaria.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior,
utilizar as cabeças femorais como referência para angulação, deixar os cortes paralelos a elas,
utilizar imagem no plano axial como referência. No plano sagital deixar os cortes paralelos ao
maior eixo do abdômen, utilizar imagem sagital como referência.
                        </p>
                        <p>
                        Para back-up os cortes coronais devem ter início antes da pele do dorso e término logo após a
pele da região ventral. 
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais filme devem ter início 1 cm antes dos rins e término 1 cm após os rins.
                        </p>
                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar imagem no plano axial como referência. 
                        </p>

                        <p>
                        Para back-up os cortes sagitais devem ter início antes da pele da face lateral esquerda do
abdômen e término logo após o final da pele da face lateral direita do abdômen.
                        </p>


                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais devem ter início 1cm antes do rim e término 1 cm após o rim direito.
                        </p>

                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais e multiplanares para complementar o estudo de acordo com a indicação.
                        </p>
                        
                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <ul>
                        <li>3D VR Ósseo é útil em demonstrar anatomia óssea, calculo e cateter.</li>
                        <li>3D VR MIP é útil em demonstrar anatomia óssea, calculo e cateter. </li>
                        </ul>


                    </section>
                </div>
            </div>
        </Container>
    )
}