import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Axial, sagital e coronal P.M. utilizar ww 250 e wl 40;<br/>
                            Axial Osso utilizar ww 2.500 e wl 500;
                        </p>
                        <p>
                            <b>Axial P.M. -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Axial Osso -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Coronal P.M. -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Sagital P.M. -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>3D VR Ombro Dir. / Esq. -</b> 1 filme na formatação de 12 imagens;<br/>
                        </p>

                        <p>
                            <b>Impressão Mista, Filme e Papel</b><br/>
                            Axial em filme; <br/>
                            Sagital em papel;<br/>
                            Coronal em papel;<br/>
                        </p>
                        <p>
                            <b>Impressão apenas em Papel</b><br/>
                            Axial em papel;<br/>
                            Sagital em papel;<br/>
                            Coronal em papel;<br/>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}