import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/ombro/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Realização e Programação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Scout: </b>Scout AP (0 grau)<br/>
                            <b>mA: </b>10<br/>
                            <b>Início: </b>S100<br />
                            <b>Fim: </b>I200
                        </p>

                        <p>
                            <b>Scout: </b>Scout Perfil (90 graus) <br/>
                            <b>mA: </b>20<br/>
                            <b>Início: </b>S100<br />
                            <b>Fim: </b>I200
                        </p>
                    </section>

                    <Title>Limites para aquisição das imagens:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Os cortes devem ser programados seguindo a orientação da superior para a inferior, deixar
como margem de segurança 4 cm acima da clavícula e 1 cm abaixo do ângulo inferior da
escápula conforme ilustração abaixo.
                        </p>

                    </section>

                    <Title>Scout em AP e Perfil com linhas de referência.</Title>
                    
                    <section className="mt-2 p-3">
                        <img className="img-fluid" src={img} alt="Linhas Referencia" />
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Descrição da série axial sem contraste I.V. (aquisição)</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial P.M.<br />
                        
                            <strong>Espessura:</strong> 1.125 / 1.125mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Axial Osso<br />
                        
                            <strong>Espessura:</strong> 1.125 / 1.125mm<br />
                            
                            <strong>Filtro:</strong> Bone<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 3 <br />
                        
                            <strong>Anotação:</strong> Volume P.M.<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 4 <br />
                        
                            <strong>Anotação:</strong> Volume Osso<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Bone<br />
                        </p>

                        <p>
                        Caso seja necessário utilizar o meio de contraste I.V. basta repetir a programação anterior,
apenas desabilitar a recon 2 e 4 com filtro de ósseo e identificar a utilização do meio de
contraste I.V.
                        </p>

                        <h6 className="text-center font-weight-bold">Descrição da série axial com contraste I.V. (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial P.M. C/C<br />
                        
                            <strong>Espessura:</strong> 1.125 / 1.125mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Volume P.M. C/C<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                    </section>

                    
                </div>
            </div>
        </Container>
    )
}