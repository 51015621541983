import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Limites para aquisição das imagens com contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        A programação da fase arterial, venosa e equilíbrio são idênticas a fase sem contraste I.V. 
                        </p>

                        <p className="text-center">
                            Descrição da série axial com contraste I.V. (aquisição).
                        </p>

                        <p>
                            <b>Recon: </b>Recon1<br/>
                            <b>Anotação: </b>Axial com contraste I.V. trifásico (Fase arterial, venosa e de equilíbrio)<br/>
                            <b>Espessura: </b>2.5 / 2.5mm<br/>
                            <b>Filtro: </b>Std
                        </p>
                        <p>
                            <b>Recon: </b>Recon 2<br/>
                            <b>Anotação: </b>Volume fase arterial<br/>
                            <b>Espessura: </b>1.25 / 1mm<br/>
                            <b>Filtro: </b>Soft
                        </p>
                        <p>
                            <b>Recon: </b>Recon 3<br/>
                            <b>Anotação: </b>Volume fase venosa <br/>
                            <b>Espessura: </b>1.25 / 1mm<br/>
                            <b>Filtro: </b>Soft
                        </p>
                        <p>
                            <b>Recon: </b>Recon 4 <br/>
                            <b>Anotação: </b>Volume fase de equilíbrio<br/>
                            <b>Espessura: </b>1.25 / 1mm<br/>
                            <b>Filtro: </b>Soft
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}