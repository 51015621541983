import { Title } from "../../components";
import { Container } from "./styles";

export default function ParametrosTecnicos(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Parâmetros Técnicos para Joelho</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Parâmetros técnicos para aparelho de 16 detectores:</h6>

                        <p>
                            <strong>Linhas do detector:</strong> 16<br />
                        
                            <strong>Espes. Helicoidal (mm):</strong> 1.125<br />
                        
                            <strong>Modo scan:</strong> 1.375:1<br />
                        
                            <strong>Vel. (mm/rot):</strong> 13.75<br />
                        </p>

                        <p>
                            <strong>Tipo de scan:</strong> Helicoidal<br />
                        
                            <strong>Tempo de rotação:</strong> 0.7 segundo<br />
                        
                            <strong>Comprimento de rotação:</strong> Total<br />
                        </p>

                        <p>
                            <strong>kV:</strong> 120<br />
                        
                            <strong>Auto - mA:</strong> Min. 100 a Máx. 250<br />
                        
                            <strong>Scan FOV:</strong> Large<br />
                            
                            <strong>Índice de ruído:</strong> 6<br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Posição do paciente:</h6>

                        <p>
                            <strong>Decúbito:</strong> Supino<br />
                        
                            <strong>Orientação do paciente:</strong> Pés primeiro<br />
                        
                            <strong>Ponto Zero:</strong> Zerar no ápice da patela<br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Realização e Programação:</h6>

                        <p>
                            <strong>Scout:</strong> Scout AP (0 grau)<br />
                        
                            <strong>mA:</strong> 10<br />
                        
                            <strong>Inicio:</strong> S 120<br />
                            
                            <strong>Fim:</strong> I 120<br />
                        </p>

                        <p>
                            <strong>Scout:</strong> Scout Perfil (90 graus)<br />
                        
                            <strong>mA:</strong> 20<br />
                        
                            <strong>Inicio:</strong> S 120<br />
                            
                            <strong>Fim:</strong> I 120<br />
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}