import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Axial e sagital P.M. utilizar ww 260 e wl 45;<br/>
                            Sagital Osso utilizar ww 2500 e wl 500;<br/>
                        </p>
                        <p>
                            <b>Axial P.M. -</b> 3 filmes na formatação de 20 imagens;<br/>
                            <b>Sagital P.M. -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Sagital Osso -</b> 1 filme na formatação de 20 imagens;<br/>
                        </p>

                        <p>
                            <b>Impressão Mista, Filme e Papel</b><br/>
                            Axial em filme; <br/>
                            Sagital em papel;<br/>
                        </p>
                        <p>
                            <b>Impressão apenas em Papel</b><br/>
                            Axial em papel;<br/>
                            Sagital em papel;<br/>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}