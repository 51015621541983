import { useState } from "react";
import { Title } from "../../components";
import { Container } from "./styles";

function BKSemContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-up</Title>
                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Enviar para Back-up:</h6>

                        <ul>
                            <li>Scout;</li>
                            <li>Axial P.M.;</li>
                            <li>Axial Osso;</li>
                            <li>Sagital P.M. L.C.A.</li>
                            <li>Sagital P.M.;</li>
                            <li>Sagital Osso;</li>
                            <li>Coronal P.M.;</li>
                            <li>Coronal Osso;</li>
                            <li>3D VR Joelho Dir. / Esq.;</li>
                            <li>3D VR Joelho Sup. / Inf.;</li>
                            <li>3D MIP Joelho Dir. / Esq.;</li>
                            <li>3D MIP Joelho Sup. / Inf.</li>
                        </ul>
                    </section>

                </div>
            </div>
        </Container>
    )
}

function BKContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-up</Title>
                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Enviar para Back-up:</h6>

                        <ul>
                            <li>Scout;</li>
                            <li>Axial Osso;</li>
                            <li>Axial P.M. S/C;</li>
                            <li>Axial P.M. C/C;</li>
                            <li>Coronal Osso;</li>
                            <li>Coronal P.M. S/C;</li>
                            <li>Coronal P.M. C/C;</li>
                            <li>Sagital Osso;</li>
                            <li>Sagital P.M. S/C;</li>
                            <li>Sagital P.M. C/C;</li>
                            <li>3D VR Joelho Dir. / Esq.;</li>
                            <li>3D VR Joelho Sup. / Inf.;</li>
                            <li>3D MIP Joelho Dir. / Esq.;</li>
                            <li>3D MIP Joelho Sup. / Inf.;</li>
                        </ul>
                    </section>

                </div>
            </div>
        </Container>
    )
}

export default function BackUp(){
    const [contraste, setContraste] = useState(null)

    if(contraste === true){
        return BKContraste()
    }else if (contraste === false){
        return BKSemContraste()
    }else{
        return (
            <Container>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <Title>Escolha uma opção</Title>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(true)
                            }}
                        >Com Contraste I.V.</button>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(false)
                            }}
                        >Sem Contraste I.V.</button>
                    </div>
                </div>
            </Container>
        )
    }   
}