import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/ouvidos/img2.PNG'
import img3 from '../../../assets/ouvidos/img3.PNG'
import img4 from '../../../assets/ouvidos/img4.PNG'
import img5 from '../../../assets/ouvidos/img5.PNG'
import img6 from '../../../assets/ouvidos/img6.PNG'
import img7 from '../../../assets/ouvidos/img7.PNG'
import img8 from '../../../assets/ouvidos/img8.PNG'
import img9 from '../../../assets/ouvidos/img9.PNG'
import img10 from '../../../assets/ouvidos/img10.PNG'
import img11 from '../../../assets/ouvidos/img11.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, utilizar o processo mastoideo como referência para angulação, deixar os cortes perpendiculares ao seu maior eixo, selecionar imagem no plano coronal e sagital como referência. <br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima do rochedo temporal e término no final do processo mastoideo. Fazer lado direito e esquerdo.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes axiais Osso devem ter início logo acima do rochedo temporal e término no final do processo mastoideo. Fazer lado direito e esquerdo.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar imagem no plano axial como referência.  Utilizar o conduto auditivo externo como referência para angulação, deixar os cortes paralelos a ele, utilizar imagem de referência no plano axial. No plano sagital utilizar o processo mastoideo como referência para angulação, deixar os cortes perpendiculares ao seu maior eixo, selecionar imagem no plano sagital como referência. <br/>
                            Para back-up, os cortes coronais P.M. (sem e com contraste I.V.) devem ter início 1cm antes das células aéreas da mastoide e término 1cm após o côndilo mandibular.  Fazer lado direito e esquerdo.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes coronais Osso devem ter início 1cm antes das células aéreas da mastoide e término 1cm após o côndilo mandibular.  Fazer lado direito e esquerdo.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. (sem e com contraste I.V.) devem ter início nas células aéreas da mastoide e término no início do côndilo mandibular. Fazer lado direito e esquerdo.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes Coronais osso devem ter início nas células aéreas da mastoide e término no início do côndilo mandibular. Fazer lado direito e esquerdo.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar o conduto auditivo externo como referência para angulação, utilizar imagem no plano axial como referência. No plano coronal utilizar o processo mastoideo como referência para angulação, deixar os cortes paralelos ao seu maior eixo, selecionar imagem no plano coronal como referência. <br/>
                            Para back-up, os cortes sagitais P.M. (sem e com contraste I.V.) do lado esquerdo devem ter início no início da orelha externa e término no dente do áxis. Do lado direito fazer o contrário. Fazer lado direito e esquerdo.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes sagitais Osso do lado esquerdo devem ter início no início da orelha externa e término no dente do áxis. Do lado direito fazer o contrário. Fazer lado direito e esquerdo.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações para complementar o estudo.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />
                        <img className="img-fluid" src={img11} alt="Reformatacoes" />
                    </section>
                </div>
            </div>
        </Container>
    )
}