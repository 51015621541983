import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/hipofise/img2.PNG'
import img3 from '../../../assets/hipofise/img3.PNG'
import img4 from '../../../assets/hipofise/img4.PNG'
import img5 from '../../../assets/hipofise/img5.PNG'
import img6 from '../../../assets/hipofise/img6.PNG'
import img7 from '../../../assets/hipofise/img7.PNG'
import img8 from '../../../assets/hipofise/img8.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Reformatações multiplanares, tridimensionais e imagens de referência: Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares aos processos clinóides, utilizar o plano sagital.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo abaixo do assoalho da sela turca e término 1 cm acima do processo clinóide.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar os processos clinóides como referência para angulação, deixar os cortes paralelos a eles, utilizar imagem no plano sagital como referência.  No plano axial deixar os cortes paralelos aos processos clinóides, utilizar imagem axial como referência.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início 0,5 cm antes processo clinóide posterior e término 0,5 cm após o processo clinóide anterior.  
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes coronais Osso devem ter início 0,5 cm antes processo clinóide posterior e término 0,5 cm após o processo clinóide anterior.  
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início no dorso as sela e término no processo clinóide anterior.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar os processos crinoides como referência para angulação, deixar os cortes perpendiculares a eles, utilizar imagem no plano axial e coronal como referência. <br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início 0,5 cm antes do processo clinóide anterior esquerdo e término 0,5cm após o processo clinóide anterior direito. 
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso devem ter início 0,5 cm antes do processo clinóide anterior esquerdo e término 0,5cm após o processo clinóide anterior direito.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) devem ter início no processo clinóide anterior esquerdo e término no processo clinóide anterior direito.  
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />
                    </section>
                </div>
            </div>
        </Container>
    )
}