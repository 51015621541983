import {
    Container
} from './styles'

import {
    Title
} from '../../components'
import { useHistory } from 'react-router-dom'

export default function Menu(){

    const history = useHistory()
    
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Escolha uma região anatômica</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 mb-4">
                    <button
                        className="btn btn-info btn-block"
                        onClick={
                            () => history.push("/cabeca")
                        }
                    >Cabeça e Pescoço</button>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 mb-4">
                    <button
                        className="btn btn-info btn-block"
                        onClick={
                            () => history.push("/coluna")
                        }
                    >Coluna Vertebral</button>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 mb-4">
                    <button
                        className="btn btn-info btn-block"
                        onClick={
                            () => history.push("/tronco")
                        }
                    >Tronco</button>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 mb-4">
                    <button
                        className="btn btn-info btn-block"
                        onClick={
                            () => history.push("/membrosSuperiores")
                        }
                    >Membros Superiores</button>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 mb-4">
                    <button
                        className="btn btn-info btn-block"
                        onClick={
                            () => history.push("/membrosInferiores")
                        }
                    >Membros Inferiores</button>
                </div>
            </div>
        </Container>
    )
}