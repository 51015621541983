import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento Sem Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                            Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2 mm de espessura e espaçamento.<br/>
                            O FOV deve ser o menor possível se adequando a cada paciente.
                        </p>

                        <p className="text-center">
                            Reformatação do pescoço sem contraste I.V. <br/>
                            Utilizar o Volume P.M. S/C (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal S/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital S/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal S/C Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital S/C Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial S/C Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Osso e fazer as seguintes reformatações:
                        </p>


                        <p>
                            <b>Axial Osso Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Coronal Osso </b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial obl. Osso</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}