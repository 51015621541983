import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/pescoco/img2.PNG'
import img3 from '../../../assets/pescoco/img3.PNG'
import img4 from '../../../assets/pescoco/img4.PNG'
import img5 from '../../../assets/pescoco/img5.PNG'
import img6 from '../../../assets/pescoco/img6.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior, selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares a coluna cervical.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início 1cm acima do palato ósseo e término 2 cm abaixo do arco aórtico.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar a coluna cervical como referência para angulação, deixar os cortes paralelos a ele, utilizar imagem no plano sagital como referência.  No plano axial deixar os cortes paralelos ao ramo mandibular, utilizar imagem axial como referência.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da musculatura posterior da região cervical e término 1 cm após a protuberância mentual.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 1 cm antes do processo espinhoso cervical e término logo após o osso hioide.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar a coluna cervical como referência para angulação, deixar os cortes paralelos a ela, utilizar imagem no plano coronal como referência. No plano axial deixar os cortes paralelos ao ramo mandibular, utilizar imagem axial como referência.<br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início 2cm antes da parótida esquerda e término 2cm após o final da parótida direita.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) devem ter início no terço médio da parótida esquerda e término no terço médio da parótida direita.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />
                    </section>
                </div>
            </div>
        </Container>
    )
}