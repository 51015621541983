import { useState } from "react";
import { Title } from "../../../components";
import { Container } from "./styles";


function PPPSemContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolos de pós-processamento - Sem Contraste</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Reformatações</h6>

                        <p>
                            Para os exames de adultos entre 1 a 3 mm de espessura e espaçamento são bem aceitos na grande maioris dos serviços e para uso pediátrico valores entre 1 a 2 mm de espessura e espaçamento.
                        </p>

                        <p>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 36 a 42 cm os mais habituais.
                        </p>

                        <p className="text-center">
                            Reformatação do tórax<br/>
                            Utilizar o Volume mediatismo e fazer as seguintes reformatações
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <p>
                            <strong>Axial Filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>40 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital Mediatismo</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Mediatismo</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <p className="text-center">
                            Utilizar o Volume Pulmão e fazer as seguintes reformatações:
                        </p>
                        <p>
                            <strong>Sagital Pulmão</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Pulmão</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal MIP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>7mm<br />
                            <strong>MIP</strong><br />
                        </p>

                        <p>
                            <strong>Axial MIP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>7mm<br />
                            <strong>MIP</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Min IP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>7mm<br />
                            <strong>Min IP</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Pulmão Filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>20 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Axial Pulmão Filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>40 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <li>
                            <ul>Fazer 12 imagens em 3D Volume Redering dos pulmões girando da direita para esquerda, salvar como 3D Pulmões Dir./Esq.</ul>
                            <ul>Fazer 12 imagens em 3D Volume Redering dos pulmões girando da superior para inferior, slavar como 3D pulões Sup./Inf.</ul>
                        </li>
                    </section>
                </div>
            </div>
        </Container>
    )
}

function PPPContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolos de pós-processamento de imagens - com contraste</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Reformatações:</h6>

                        <p>
                            Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2 mm de espessura e espaçamento.
                        </p>

                        <p>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 36 a 42 cm os mais habituais.
                        </p>

                        <p className="text-center">
                            Utilizar o Volume mediastino S/C e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <strong>Sagital Mediastino S/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Mediastino S/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                    </section>

                    <section className="mt-2 p-3">
                        <p className="text-center">Utilizar o Volume mediastino S/C e fazer as seguintes reformatações:</p>

                        <p>
                            <strong>Sagital Mediastino S/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                        <p>
                            <strong>Coronal Mediastino S/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Utilizar o Volume mediastino C/C e fazer as seguintes reformatações:</h6>
                        <p>
                            <strong>Axial  filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>40 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Filme </strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital Mediastino C/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital Mediastino C/C</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <p className="text-center">
                            Utilizar o Volume Pulmão e fazer as seguintes reformatações:
                        </p>
                        <p>
                            <strong>Sagital Pulmão</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                        <p>
                            <strong>Coronal Pulmão</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>Espaçamento: </strong>2mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Axial Pulmão</strong><br />
                            <strong>Espessura: </strong>1mm<br />
                            <strong>Espaçamento: </strong>10mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal MIP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>7mm<br />
                            <strong>MIP</strong><br />
                        </p>
                        <p>
                            <strong>Axial MIP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>7mm<br />
                            <strong>MIP</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Min IP</strong><br />
                            <strong>Espessura: </strong>15mm<br />
                            <strong>Espaçamento: </strong>17mm<br />
                            <strong>Min IP</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Pulmão filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>20 Imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Axial Pulmão filme</strong><br />
                            <strong>Espessura: </strong>2mm<br />
                            <strong>40 Imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <li>
                            <ul>Fazer 12 imagens em 3D Volume Redering dos pulmões girando da direita para esquerda, salvar como 3D Pulmões Dir./Esq.</ul>
                            <ul>Fazer 12 imagens em 3D Volume Redering dos pulmões girando da superior para inferior, slavar como 3D pulões Sup./Inf.</ul>
                        </li>
                    </section>

                </div>
            </div>
        </Container>
    )
}

export default function ProtocolosPosProcessamento(){
    const [contraste, setContraste] = useState(null)

    if(contraste === true){
        return PPPContraste()
    }else if (contraste === false){
        return PPPSemContraste()
    }else{
        return (
            <Container>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <Title>Escolha uma opção</Title>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(true)
                            }}
                        >Com Contraste I.V.</button>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(false)
                            }}
                        >Sem Contraste I.V.</button>
                    </div>
                </div>
            </Container>
        )
    }
}