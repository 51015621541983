import { Title } from "../../components";
import { Container } from "./styles";

import img2 from '../../assets/joelho/img2.PNG'
import img3 from '../../assets/joelho/img3.PNG'
import img4 from '../../assets/joelho/img4.PNG'
import img5 from '../../assets/joelho/img5.PNG'
import img6 from '../../assets/joelho/img6.PNG'
import img7 from '../../assets/joelho/img7.PNG'
import img8 from '../../assets/joelho/img8.PNG'
import img9 from '../../assets/joelho/img9.PNG'
import img10 from '../../assets/joelho/img10.PNG'
import img11 from '../../assets/joelho/img11.PNG'
import img12 from '../../assets/joelho/img12.PNG'
import img13 from '../../assets/joelho/img13.PNG'
import img14 from '../../assets/joelho/img14.PNG'

export default function ReferenciasAnatomicas(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Referências anatômicas para pós-processamento de imagens</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Reformatações multiplanares, tridimensionais e imagens de referência:</h6>

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior, selecionar imagem no plano sagital como referência. Deixar os cortes paralelos ao platô tibial, utilizar o plano coronal.
                        </p>

                        <p>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima da base da patela e término 1 cm abaixo da tuberosidade anterior da tíbia.
                        </p>

                        <img className="img-fluid" src={img2} alt="Joelho" />

                        <p>
                            Para filme, os cortes axiais Osso devem ter início logo acima da base da patela e término 1 cm abaixo da tuberosidade anterior da tíbia.
                        </p>

                        <img className="img-fluid" src={img3} alt="Joelho" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar os côndilos femorais como referência para angulação, deixar os cortes paralelos a eles, utilizar imagem no plano axial como referência. No plano sagital deixar os cortes paralelos ao fêmur / tíbia, utilizar imagem sagital como referência.
                        </p>

                        <p>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da musculatura posterior da articulação do joelho e término logo após a patela.
                        </p>

                        <img className="img-fluid" src={img4} alt="Joelho" />
                        
                        <p>
                            Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior da articulação do joelho e término logo após a patela.
                        </p>

                        <img className="img-fluid" src={img5} alt="Joelho" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 0.5 cm antes dos côndilos posteriores do fêmur e término 0,5 cm após o fêmur.
                        </p>

                        <img className="img-fluid" src={img6} alt="Joelho" />

                        <p>
                            Para filme, os cortes coronais Osso filme devem ter início 0.5 cm antes dos côndilos femorais e término 0,5 cm após o fêmur.
                        </p>

                        <img className="img-fluid" src={img7} alt="Joelho" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar os côndilos femorais como referência para angulação, selecionar imagem no plano axial como referência, deixar os cortes perpendiculares aos côndilos femorais. No plano coronal deixar os cortes perpendiculares ao platô tibial, utilizar imagem coronal como referência.
                        </p>

                        <p>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do joelho direito devem ter início 2cm do epicôndilo medial e término 2cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 2cm do epicôndilo lateral e término 2cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img8} alt="Joelho" />

                        <p>
                            Para back-up os cortes sagitais Osso do joelho direito devem ter início 2cm do epicôndilo medial e término 2cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 2cm do epicôndilo lateral e término 2cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img9} alt="Joelho" />

                        <p>
                            Para filme, os cortes sagitais P.M. (sem e com contraste I.V.) do joelho direito devem ter início 0.5 cm do epicôndilo medial e término 0.5 cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 0.5 cm do epicôndilo lateral e término 0.5 cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img10} alt="Joelho" />

                        <p>
                            Para filme, os cortes sagitais Osso do joelho direito devem ter início 0.5 cm do epicôndilo medial e término 0.5 cm do epicôndilo lateral. O joelho esquerdo os cortes devem ter início 0.5 cm do epicôndilo lateral e término 0.5 cm do epicôndilo medial.
                        </p>

                        <img className="img-fluid" src={img11} alt="Joelho" />

                        <p>
                            Para back-up os cortes sagitais P.M. L.C.A. do joelho direito devem ter início 1cm médio lateral do L.C.A. e término 1cm latero medial do L.C.A.. O joelho esquerdo os cortes devem ter início 1cm latero medial do L.C.A. e término 1cm médio lateral do L.C.A.
                        </p>

                        <img className="img-fluid" src={img12} alt="Joelho" />

                        <p>
                            Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do Joelho de acordo com a indicação.
                        </p>

                        <p className="text-center">
                            3D VR (parte óssea)
                        </p>

                        <img className="img-fluid" src={img13} alt="Joelho" />

                        <p className="text-center">
                            3D MIP (parte óssea)
                        </p>

                        <img className="img-fluid" src={img14} alt="Joelho" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>

                    </section>
                </div>
            </div>
        </Container>
    )
}