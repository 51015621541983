import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Referências</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>
                        Almeida MFA, Verza L, Bitencourt AGV, Boaventura CS, Barbosa PNVP, Chojniak R Tomografia computadorizada com protocolo gástrico e de gastroscopia virtual no estadiamento do câncer gástrico: experiência inicial, Radiol Bras. 2018 Jul/Ago; 51(4):211–17.
                    </p>
                    <p>
                        Araujo-Neto SA, Mello-Júnior CF, Franca HA, Duarte CMA, Borges RF, Magalhães AGX. Angiotomografia multidetectores do tronco celíaco e sistema arterial hepático: anatomia normal e suas principais variantes, Radiol Bras. 2016 Jan/Fev;49(1):49–52.
                    </p>
                    <p>
                        Barra DCC, Paim SMS, Dal Sasso GTM, Colla GW. Métodos para desenvolvimento de aplicativos móveis em saúde: revisão integrativa da literatura, Texto Contexto Enferm, 2017; 26(4):e2260017.
                    </p>
                    <p>
                        Bashshur RL, Krupinski EA, Thrall JH, Bashshur N. Os fundamentos empíricos da telerradiologia e aplicações relacionadas: uma revisão das evidências, Telemed JE Health. nov. 2016;22 (11): 868–98.
                    </p>
                    <p>
                        Costa Jr. AS, Gellada N.  Uma nova realidade: renderização cinematográfica para reconstruções tridimensionais da parede torácica. Einstein (São Paulo). 2020;18:eMD5223. http://dx.doi.org/10.31744/einstein_journal/2020MD5223
                    </p>
                    <p>
                        Estanislau LJM, Morais AP, Cabral RO, Lorena Sobrinho JE, Lorena SB. Uso de aplicativos de tecnologia móvel na rotina de estudantes concluintes de medicina, Reciis – Rev Eletron Comun Inf Inov Saúde. 2019 jul.-set.;13(3):569-77. 
                    </p>
                    <p>
                        Ghekiere O, Salgado R, Buls N, Leiner T, Mancini I, Vanhoenacker P, Dendale P, Nchimi A. Qualidade da imagem em angiotomografia coronária: desafios e soluções técnicas, Br J Radiol.Abril de 2017;90 (1072): 20160567.
                    </p>
                    <p>
                        Lima CSP, Barbosa FF. Aplicativos móveis em saúde: caracterização da produção científica da enfermagem brasileira, Rev. Eletr. Enferm., 2019; 21:53278, 1-11.
                    </p>
                    <p>
                        Machado RCG, Turrini RNT, Sousa CS. Aplicativos de celular na educação em saúde do paciente cirúrgico:uma revisão integrativa, Rev Esc Enferm USP · 2020;54:e03555.
                    </p>
                    <p>
                        Mello Júnior CF, Araujo Neto SA, Carvalho Junior AM, Rebouças RB, Negromonte GRP, Oliveira CD. Angiotomografia computadorizada por multidetectores das artérias renais: anatomia normal e suas variações, Radiol Bras. 2016 Mai/Jun;49(3):190-5.
                    </p>
                    <p>
                        Mello NC, Góes FGB, Pereira-Ávila FMV, Moraes JRMM,Silva LF, Silva MA. Construção e validação de cartilha educativa para dispositivos móveis sobre aleitamento materno, Texto Contexto Enferm.2020, v. 29: e20180492
                    </p>
                    <p>
                        Neves NTAT, Araújo YB, Costa Cmelo, Cardoso ELS, Ferreira EMV. Tendências de estudos sobre aplicativos móveis para saúde: revisão integrativa, J. health inform, 2016; 8(supl.I): 499-507.
                    </p>
                    <p>
                        Rahimi SA,Menear M,Robitaille H,Légaré F. Os aplicativos móveis de saúde são úteis para apoiar a tomada de decisão compartilhada nas decisões de diagnóstico e tratamento? Glob Health Action.2017;10 (sup3): 1332259
                    </p>
                    <p>
                        Rocha TAH, Fachini LA, Thumé E, Silva NCS, Barbosa ACQ, Carmo M, Rodrigues JM. Saúde Móvel: novas perspectivas para a oferta de serviços em saúde, Epidemiol. Serv. Saúde, Brasília, jan-mar 2016; 25(1):159-70. 
                    </p>
                    <p>
                        Santos TR, Soares LG, Machado LDS, Brito NS, Palácio MAV, Silva MRF. Uso de aplicativos móveis no processo de ensino-aprendizagem na graduação em enfermagem, Rev baiana enferm. 2021; 35:e37136.
                    </p>
                </div>
            </div>
        </Container>
    )
}