import { Title } from '../../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-Up</Title>

                    <section className="mt-2 p-3">

                        <ul>
                            <li>Scout;</li>
                            <li>Axial S/C</li>
                            <li>Coronal S/C</li>
                            <li>Axial C/C Trifásico</li>
                            <li>Coronal C/C Fase Venosa;</li>
                            <li>Sagital C/C Fase Venosa;</li>
                            <li>Relatório de dose;</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}