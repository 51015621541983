import { Title } from "../../../components";
import { Container } from "./styles";

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento com Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                        Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento
são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2
mm de espessura e espaçamento.<br/>
O FOV deve ser o menor possível se adequando a cada paciente sendo os valores
entre 32 a 42 cm os mais habituais.<br/>
As reformatações tridimensionais tem grande valor informativo, sendo feitas como
rotina em muitos serviços.
                        </p>

                        <p className="text-center">
                        Utilizar o Volume Osso e fazer as seguintes reformatações:
                        </p>
                        <p>
                            <b>Axial Osso Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>24 imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>


                        <p className="text-center">
                        Utilizar o Volume P.M (partes moles) sem contraste I.V. e fazer as seguintes reformatações:
                        </p>

                        <ul>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering da Bacia girando da direita para esquerda, salvar como 3D VR Bacia Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering da bacia girando da superior para inferior, salvar como 3D VR Bacia Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo da bacia girando da direita para esquerda, salvar como 3D MIP Bacia Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo da bacia girando da superior para inferior, salvar como 3D MIP Bacia Sup. / Inf.</li>
                        </ul>

                        <p>
                            <b>Axial P.M. S/C filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. S/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. S/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            Utilizar o Volume P.M. (partes moles) com contraste I.V. e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial P.M. C/C filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>24 Imagens </b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. C/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. C/C</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (Média): </b>
                        </p>


                        <p>
                            <b>Coronal P.M. C/C Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M. C/C Filme</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}