import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/torax/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">

                    <Title>Realização e Programação:</Title>
                    <section className="mt-2 p-3">

                        <p>
                            <strong>Scout:</strong> Scout AP (0 grau)<br />
                        
                            <strong>mA:</strong> 10<br />
                        
                            <strong>Inicio:</strong> S 100<br />
                            
                            <strong>Fim:</strong> I 350<br />
                        </p>

                        <p>
                            <strong>Scout:</strong> Scout Perfil (90 graus)<br />
                        
                            <strong>mA:</strong> 30<br />
                        
                            <strong>Inicio:</strong> S 100<br />
                            
                            <strong>Fim:</strong> I 350<br />
                        </p>
                    </section>
                    
                    <section className="mt-2 p-3">
                        <img className="img-fluid" src={img} alt="Aquisição de Imagens" />
                    </section>

                    <section className="mt-2 p-3">
                        <p>Scout em AP e Perfil com linhas de referência.</p>
                        <h6 className="text-center font-weight-bold">Descrição da série axial S/C (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial Mediastino<br />
                        
                            <strong>Espessura:</strong> 1.25 / 1.25mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Axial Pulmão<br />
                        
                            <strong>Espessura:</strong> 1.125/1.125mm<br />
                            
                            <strong>Filtro:</strong> Lung<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 3 <br />
                        
                            <strong>Anotação:</strong> Axial Pulmão A.R.<br />
                        
                            <strong>Espessura:</strong> 1.25/10mm<br />
                            
                            <strong>Filtro:</strong> Bone<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 4 <br />
                        
                            <strong>Anotação:</strong> Volume Mediastino<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 5 <br />
                        
                            <strong>Anotação:</strong> Volume Pulmão<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p className="text-center">
                            Descrição da série axial C/C (aquisição).
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial Mediastino C/C<br />
                        
                            <strong>Espessura:</strong> 1.25 / 1.25mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Volume Mediastino C/C<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            Caso seja necessário utilizar o meio de contraste I.V. basta repetir a programação anterior e desabilitar as séries de pulmão.
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}