import styled from 'styled-components'

const Container = styled.h1`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
`

export {
    Container
}