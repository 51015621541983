import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/abdomenContraste/img2.png'
import img2 from '../../../assets/abdomenContraste/img3.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Scout em AP e Perfil com linhas de referência.</Title>
                    
                    <section className="mt-2 p-3">
                        <img className="img-fluid" src={img} alt="Aquisição de Imagens" />
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Descrição da série axial com contraste I.V. (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial com contraste I.V. trifásico (Fase arterial, portal e de equilíbrio)<br />
                        
                            <strong>Espessura:</strong> 2.5 / 2.5mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Volume fase arterial <br />
                        
                            <strong>Espessura:</strong> 1.25 / 1mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 3 <br />
                        
                            <strong>Anotação:</strong> Volume fase portal<br />
                        
                            <strong>Espessura:</strong> 1.25/1mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 4 <br />
                        
                            <strong>Anotação:</strong> Volume fase de equilíbrio<br />
                        
                            <strong>Espessura:</strong> 1.25 / 1mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <img className="img-fluid" alt="Linhas Referencia" src={img2} />
                    </section>
                </div>
            </div>
        </Container>
    )
}