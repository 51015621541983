import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/ombro/img2.png'
import img3 from '../../../assets/ombro/img3.png'
import img4 from '../../../assets/ombro/img4.png'
import img5 from '../../../assets/ombro/img5.png'
import img6 from '../../../assets/ombro/img6.png'
import img7 from '../../../assets/ombro/img7.png'
import img8 from '../../../assets/ombro/img8.png'
import img9 from '../../../assets/ombro/img9.png'
import img10 from '../../../assets/ombro/img10.png'
import img11 from '../../../assets/ombro/img11.png'
import img12 from '../../../assets/ombro/img12.png'
import img13 from '../../../assets/ombro/img13.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares ao
úmero, utilizar o plano sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima da
articulação acrômio clavicular e término 2 cm abaixo da cavidade glenoide.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima da articulação acrômio clavicular
e término 2 cm abaixo da cavidade glenoide.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar o corpo da escápula como referência para angulação, deixar os cortes paralelos
a ele, utilizar imagem no plano axial como referência. No plano sagital deixar os cortes paralelos
ao úmero, utilizar imagem sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior e término logo após o a musculatura anterior do ombro.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior e término
logo após o a musculatura anterior do ombro.
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 1 cm antes
da cabeça do úmero e término 1 cm após a cabeça do úmero.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início 1 cm antes da cabeça do úmero e
término 1 cm após a cabeça do úmero.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar o corpo da escapula como referência para angulação, utilizar imagem no plano axial como
referência. No plano sagital deixar os cortes paralelos ao úmero, utilizar imagem sagital como
referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) do ombro direito devem ter início
antes da margem medial da escapula e término logo após o final da musculatura que envolve a
cabeça do úmero e do ombro esquerdo o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso do ombro direito devem ter início antes da margem medial
da escapula e término logo após o final da musculatura que envolve a cabeça do úmero e do
ombro esquerdo o contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) do ombro direito devem ter
início 3 cm antes da cavidade glenóide e término 1 cm após a cabeça do úmero e para o ombro
esquerdo devem ter início 1 cm antes da cabeça do úmero e término 3 cm após a cavidade
glenóide.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso filme do ombro direito devem ter início 3 cm antes da
cavidade glenóide e término 1 cm após a cabeça do úmero e para o ombro esquerdo devem ter
início 1 cm antes da cabeça do úmero e término 3 cm após a cavidade glenóide.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p className="text-center">
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do
ombro de acordo com a indicação.
                        </p>

                        <p className="text-center">
                            3D MIP (parte óssea)
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />
                        <img className="img-fluid" src={img13} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}