import { useState } from "react";
import { Title } from "../../../components";
import { Container } from "./styles";

function DOCSemContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>
                    <section className="mt-2 p-3">

                        <ul>
                            <li>Axial e coronal mediastino utilizar ww 350 e wl 40;</li>
                            <li>Axial e coronal pulmão utilizar ww 1.200 e wl -700;</li>
                        </ul>

                        <ul>
                            <li><strong>Axial mediastino - </strong>2 filmes na formatação de 20 imagens</li>
                            <li><strong>Coronal mediastino - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Axial Pulmão - </strong>2 filmes na formatação de 20 imagens;</li>
                            <li><strong>Coronal Pulmão - </strong>1 filme na formatação de 20 imagens;</li>
                        </ul>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão Mista, Filme e Papel</h6>

                        <p>Axial em filme</p>
                        <p>Coronal em papel</p>
                        <p>3D Pulmões em papel</p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão apenas em papel</h6>

                        <p>Axial em papel</p>
                        <p>Coronal em papel</p>
                        <p>3D Pulmões em papel</p>

                    </section>
                </div>
            </div>
        </Container>
    )
}

function DOCContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>
                    <section className="mt-2 p-3">

                        <ul>
                            <li>Axial e Coronal mediastino utilizar ww 350 e wl 40</li>
                            <li>Axial e coronal pulmão utilizar ww 1.200 e wl -700</li>
                        </ul>

                        <ul>
                            <li><strong>Axial Mediastino C/C - </strong>2 filmes na formatação de 20 imagens;</li>
                            <li><strong>Coronal Mediastino C/C - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Axial Pulmão - </strong>2 filmes na formatação de 20 imagens;</li>
                            <li><strong>Coronal Pulmão - </strong>1 filme na formatação de 20 imagens;</li>
                        </ul>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão Mista, Filme e Papel</h6>

                        <p>Axial em filme</p>
                        <p>Coronal em papel</p>
                        <p>3D Pulmões em papel</p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão apenas em papel</h6>

                        <p>Axial em papel</p>
                        <p>Coronal em papel</p>
                        <p>3D Pulmões em papel</p>

                    </section>
                </div>
            </div>
        </Container>
    )
}

export default function Documentacao(){
    const [contraste, setContraste] = useState(null)

    if(contraste === true){
        return DOCContraste()
    }else if (contraste === false){
        return DOCSemContraste()
    }else{
        return (
            <Container>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <Title>Escolha uma opção</Title>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(true)
                            }}
                        >Com Contraste I.V.</button>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(false)
                            }}
                        >Sem Contraste I.V.</button>
                    </div>
                </div>
            </Container>
        )
    }
}