import { useState } from "react";
import { Title } from "../../components";
import { Container } from "./styles";

function DOCSemContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>
                    <section className="mt-2 p-3">

                        <ul>
                            <li>Axial, sagital e coronal P.M. utilizar ww 280 e wl 40;</li>
                            <li>Axial Osso utilizar ww 2.500 e wl 500;</li>
                        </ul>

                        <ul>
                            <li><strong>Axial P.M. - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Axial Osso - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Coronal P.M. - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Sagital P.M. - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>3D VR Joelho Dir. / Esq. - </strong>1 filme na formatação de 12 imagens;</li>
                        </ul>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão Mista, Filme e Papel</h6>

                        <p>Axial em filme</p>
                        <p>Sagital em papel</p>
                        <p>Coronal em papel</p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão apenas em papel</h6>

                        <p>Axial em papel</p>
                        <p>Sagital em papel</p>
                        <p>Coronal em papel</p>

                    </section>
                </div>
            </div>
        </Container>
    )
}

function DOCContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>
                    <section className="mt-2 p-3">

                        <ul>
                            <li>Axial Osso utilizar ww 2.500 e wl 500;</li>
                            <li>Axial P.M. S/C e C/C utilizar ww 250 e wl 40;</li>
                            <li>Coronal e sagital P.M. C/C utilizar ww 250 e wl 40;</li>
                        </ul>

                        <ul>
                            <li><strong>Axial Osso - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Axial P.M. S/C - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Axial P.M. C/C - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Sagital P.M. C/C - </strong>1 filme na formatação de 20 imagens;</li>
                            <li><strong>Coronal P.M. C/C - </strong>1 filme na formatação de 20 imagens;</li>
                        </ul>

                        <p className="text-center">Sempre que utilizar o meio de contraste I.V. identificar no filme.</p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão Mista, Filme e Papel</h6>

                        <p>Axial em filme</p>
                        <p>Sagital em papel</p>
                        <p>Coronal em papel</p>

                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Impressão apenas em papel</h6>

                        <p>Axial em papel</p>
                        <p>Sagital em papel</p>
                        <p>Coronal em papel</p>

                    </section>
                </div>
            </div>
        </Container>
    )
}

export default function Documentacao(){
    const [contraste, setContraste] = useState(null)

    if(contraste === true){
        return DOCContraste()
    }else if (contraste === false){
        return DOCSemContraste()
    }else{
        return (
            <Container>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <Title>Escolha uma opção</Title>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(true)
                            }}
                        >Com Contraste I.V.</button>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(false)
                            }}
                        >Sem Contraste I.V.</button>
                    </div>
                </div>
            </Container>
        )
    }
}