import { Title } from '../../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Axial S/C utilizar ww 350 e wl 40
                            <br/>
                            Axial Sagital e coronal C/C utilizar ww 350 e wl 50;
                        </p>
                        <p>
                            <b>Axial S/C -</b> 1 filme na formatação de 24 imagens;<br/>
                            <b>Axial C/C Fase Venosa</b> - 1 filme na formatação de 24 imagens;<br/>
                            <b>Coronal C/C Fase Venosa</b> - 1 filme na formatação de 20 imagens;<br/>
                            <b>Sagital C/C Fase Venosa</b> - 1 filme na formatação de 20 imagens; <br/>
                        </p>

                        <p>
                            <b>Impressão Mista, Filme e Papel</b><br/>
                            Axial em filme; <br/>
                            Sagital papel;<br/>
                            Coronal em papel;<br/>
                        </p>
                        <p>
                            <b>Impressão apenas em Papel</b><br/>
                            Axial em papel;<br/>
                            Sagital papel;<br/>
                            Coronal em papel;<br/>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}