import { useHistory } from 'react-router-dom'
import { Title } from '../../../../components'
import {
    Container
} from './styles'

export default function (){
    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Para os exames de adultos os valores entre 1 a 3mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2mm de espessura e espaçamento.
                        </p>

                        <p>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 36 a 42cm os mais habituais. 
                        </p>

                        <p className="text-center font-weight-bold">
                            Reformatação do tórax S/C
                        </p>

                        <p>
                            Utilizar o Volume mediastino e fazer as seguintes reformatações
                        </p>

                        <p>
                            <b>Axial Filme</b><br/>
                            <b>Espessura: </b> 2mm<br/>
                            <b>24 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal Filme</b><br/>
                            <b>Espessura: </b> 2mm<br/>
                            <b>20 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital Mediastino</b><br/>
                            <b>Espessura: </b> 2mm<br/>
                            <b>Espaçamento: </b> 2mm <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal Mediastino</b><br/>
                            <b>Espessura: </b> 2mm<br/>
                            <b>Espaçamento: </b> 2mm <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            Utilizar o Volume Pulmão e fazer as seguintes reformatações
                        </p>

                        <p>
                            <b>Sagital Pulmão</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal Pulmão</b><br/>
                            <b>Espessura: </b>1.5mm<br/>
                            <b>Espaçamento: </b>1.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal MIP</b><br/>
                            <b>Espessura: </b>15mm<br/>
                            <b>Espaçamento: </b>7mm<br/>
                            <b>MIP</b>
                        </p>

                        <p>
                            <b>Axial MIP</b><br/>
                            <b>Espessura: </b>15mm<br/>
                            <b>Espaçamento: </b>7mm<br/>
                            <b>MIP</b>
                        </p>

                        <p>
                            <b>Coronal Min IP</b><br/>
                            <b>Espessura: </b>15mm<br/>
                            <b>Espaçamento: </b>7mm<br/>
                            <b>Min IP</b>
                        </p>

                        <p>
                            <b>Coronal Pulmão Filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média)</b>
                        </p>

                        <p>
                            <b>Axial Pulmão Filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>36 Imagens</b><br/>
                            <b>Averege (Média)</b>
                        </p>

                        <p>
                            Fazer 12 imagens em 3D Volume Redering dos pulmões girando da direita para esquerda, salvar como 3D Pulmões Dir. / Esq.
                        </p>

                        <p>
                            Fazer 12 imagens em 3D Volume Redering dos pulmões girando da superior para inferior, salvar como 3D Pulmões Sup. / Inf.
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}