import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Documentação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Axial e coronal P.M.  utilizar ww 140 e wl 45;<br/>
                            Axial, Sagital e Coronal Osso utilizar ww 2.5000 e wl 500;
                        </p>
                        <p>
                            <b>Axial P.M. Boca Fechada -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Axial Osso Boca Fechada -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Sagital P.M. Boca Fechada ATM Direita e esquerda -</b> 1 filme na formatação de 12 imagens (6 imagens da direita e 6 da esquerda que melhor demonstre a ATM);<br/>
                            <b>Sagital P.M. Boca Aberta ATM Direita e esquerda -</b> 1 filme na formatação de 12 imagens (6 imagens da direita e 6 da esquerda que melhor demonstre a ATM);<br/>
                            <b>Coronal Osso Boca Fechada ATM Direita -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>Coronal Osso Boca Fechada ATM Esquerda -</b> 1 filme na formatação de 20 imagens;<br/>
                            <b>3D VR ATM Boca Aberta e Fechada Dir. / Esq. -</b> 1 filme na formatação de 12 imagens; (3 imagens da direita e 3 da esquerda que melhor demonstre a ATM Boca aberta e fechada);<br/>
                        </p>

                        <p>
                            <b>Impressão Mista, Filme e Papel</b><br/>
                            Axial em papel; <br/>
                            Coronal em filme;<br/>
                            Sagital em papel;<br/>
                            3D ATM em Papel;
                        </p>
                        <p>
                            <b>Impressão apenas em Papel</b><br/>
                            Axial em papel;<br/>
                            Coronal em papel;<br/>
                            Sagital em papel;<br/>
                            3D ATM em papel;
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}