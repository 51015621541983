import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/coxa/img2.png'
import img3 from '../../../assets/coxa/img3.png'
import img4 from '../../../assets/coxa/img4.png'
import img5 from '../../../assets/coxa/img5.png'
import img6 from '../../../assets/coxa/img6.png'
import img7 from '../../../assets/coxa/img7.png'
import img8 from '../../../assets/coxa/img8.png'
import img9 from '../../../assets/coxa/img9.png'
import img10 from '../../../assets/coxa/img10.png'
import img11 from '../../../assets/coxa/img11.png'
import img12 from '../../../assets/coxa/img12.png'
import img13 from '../../../assets/coxa/img13.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares ao
fêmur, utilizar o plano sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima do teto
do acetábulo e término 1 cm abaixo do platô tibial.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima do teto do acetábulo e término 1 cm abaixo do platô tibial.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os côndilos posteriores do fêmur como referência para angulação, deixar os
cortes paralelos a eles, utilizar imagem no plano axial como referência. No plano sagital deixar
os cortes paralelos ao fêmur, utilizar imagem sagital como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior da coxa e término logo após o a musculatura anterior da coxa conforme
demonstrado na referência sagital.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior da coxa
e término logo após o a musculatura anterior da coxa conforme demonstrado na referência
sagital.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início antes da
musculatura posterior da coxa e término logo após a musculatura anterior da coxa.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início antes da musculatura posterior da
coxa e término logo após a musculatura anterior da coxa.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar os côndilos posteriores do fêmur como referência para angulação, deixar os cortes
perpendiculares a eles, utilizar imagem no plano axial como referência. No plano coronal deixar os
cortes paralelos ao fêmur, utilizar imagem coronal como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) da coxa direita devem ter início
antes da face medial da coxa e término logo após o final da face lateral da coxa e da coxa
esquerda, o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso da coxa direita devem ter início antes da face medial da
coxa e término logo após o final da face lateral da coxa e da coxa esquerda, o contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) da coxa direita devem ter
início logo após musculatura da face medial da coxa e término logo após o final da musculatura da
face lateral da coxa e da coxa esquerda, o contrário.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso filme da coxa direita devem ter início logo após musculatura
da face medial da coxa e término logo após o final da musculatura da face lateral da coxa e da
coxa esquerda, o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo Da coxa de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />
                        <img className="img-fluid" src={img13} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}