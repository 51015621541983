import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/atm/img2.PNG'
import img3 from '../../../assets/atm/img3.PNG'
import img4 from '../../../assets/atm/img4.PNG'
import img5 from '../../../assets/atm/img5.PNG'
import img6 from '../../../assets/atm/img6.PNG'
import img7 from '../../../assets/atm/img7.PNG'
import img8 from '../../../assets/atm/img8.PNG'
import img9 from '../../../assets/atm/img9.PNG'
import img10 from '../../../assets/atm/img10.PNG'
import img11 from '../../../assets/atm/img11.PNG'
import img12 from '../../../assets/atm/img12.PNG'
import img13 from '../../../assets/atm/img13.PNG'
import img14 from '../../../assets/atm/img14.PNG'
import img15 from '../../../assets/atm/img15.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, utilizar o ramo mandibular como referência para angulação, deixar os cortes perpendiculares, selecionar imagem no plano sagital como referência. No plano coronal para estudo bilateral deixar os cortes paralelos aos processos condilares e nos estudos unilaterais deixar perpendicular ao ramo mandibular.<br/>
                            Para filme, os cortes Axial P.M. Boca fechada ou aberta unilateral devem ter início logo abaixo da incisura mandibular e término logo após o final da fossa mandibular.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes Axial Osso Boca fechada ou aberta unilateral devem ter início logo abaixo da incisura mandibular e término logo após o final da fossa mandibular.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes Axial P.M. Boca fechada ou aberta bilateral devem ter início logo abaixo da incisura mandibular e término logo após o final da fossa mandibular.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes Axial Osso Boca fechada ou aberta bilateral devem ter início logo abaixo da incisura mandibular e término logo após o final da fossa mandibular.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar imagem no plano sagital como referência, deixar os cortes paralelos ao ramo mandibular.  No plano axial deixar os cortes perpendiculares a cabeça da mandíbula.  Fazer lado direito e esquerdo.<br/>
                            Para back-up, os cortes Coronal Osso boca fechada deve ter início 2cm antes do côndilo mandibular e término 2cm após o côndilo mandibular.   
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes Coronal P.M. boca fechada deve ter início 2cm antes do côndilo mandibular e término 2cm após o côndilo mandibular.  
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais Osso filme devem ter início antes do côndilo mandibular e logo após o côndilo mandibular.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme devem ter início antes do côndilo mandibular e logo após o côndilo mandibular.
                        </p>

                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar a cabeça da mandíbula como referência para angulação, deixar os cortes perpendiculares e ela, utilizar imagem no plano axial como referência. No plano coronal deixar os cortes paralelos ao ramo mandibular. Fazer lado direito e esquerdo.<br/>
                            Para back-up, os cortes sagitais Osso boca aberta e fechada devem ter início 2cm anterior a cabeça da mandíbula e término 2cm após a cabeça da mandíbula. 
                        </p>
                        
                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                            Para back-up, os cortes sagitais P.M. boca aberta e fechada devem ter início 2cm anterior a cabeça da mandíbula e término 2cm após a cabeça da mandíbula. 
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais Osso boca aberta e fechada devem ter início anterior a cabeça da mandíbula e término após a cabeça da mandíbula. 
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes sagitais P.M. boca aberta e fechada devem ter início anterior a cabeça da mandíbula e término após a cabeça da mandíbula.
                        </p>

                        <img className="img-fluid" src={img13} alt="Reformatacoes" />

                        <p>
                            Abaixo algumas opções de reconstruções tridimensionais para complementar o estudo do crânio de acordo com a indicação.
                        </p>
                        <img className="img-fluid" src={img14} alt="Reformatacoes" />
                        <img className="img-fluid" src={img15} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.  </li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}