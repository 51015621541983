import { Title } from "../../components";
import { Container } from "./styles";

import img from '../../assets/joelho/img1.PNG'

export default function AquisicaoImagens(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Aquisição de imagens para Joelho</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Limites para aquisição das imagens:</h6>

                        <p>
                            Os cortes devem ser programados seguindo a orientação da superior para a inferior, deixar como margem de segurança 3 cm acima da base patela e 2 cm abaixo da tuberosidade anterior da tíbia conforme ilustração abaixo.
                        </p>

                        <p className="text-center">
                            Scout em AP e Perfil com linhas de referência.
                        </p>

                        <img className="img-fluid" src={img} alt="Aquisição de Imagens" />
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Descrição da série axial sem contraste I.V. (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial P.M.<br />
                        
                            <strong>Espessura:</strong> 1.125/1.125mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Axial Osso<br />
                        
                            <strong>Espessura:</strong> 1.125/1.125mm<br />
                            
                            <strong>Filtro:</strong> Bone<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 3 <br />
                        
                            <strong>Anotação:</strong> Volume P.M.<br />
                        
                            <strong>Espessura:</strong> 0.625/0.625mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 4 <br />
                        
                            <strong>Anotação:</strong> Volume Osso<br />
                        
                            <strong>Espessura:</strong> 0.625/0.625mm<br />
                            
                            <strong>Filtro:</strong> Bone<br />
                        </p>

                        <p>
                            Caso seja necessário utilizar o meio de contraste I.V. basta repetir a programação anterior, apenas desabilitar as recons com filtro ósseo e identificar a utilização do meio de contraste I.V.
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Descrição da série axial com contraste I.V. (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial P.M. C/C<br />
                        
                            <strong>Espessura:</strong> 1.125 /1.125 mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Volume P.M. C/C<br />
                        
                            <strong>Espessura:</strong> 0.625 / 0.625 mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}