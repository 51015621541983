import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">

            <div className="row mt-4">
                <div className="col text-center">
                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/autores")}
                    >Autores</button>

                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/desenvolvedor")}
                    >Desenvolvedor</button>

                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/objetivo")}
                    >Objetivo</button>

                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/orientacoes")}
                    >Orientações gerais</button>

                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/referencias")}
                    >Referências</button>

                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/contato")}
                    >Contato</button>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col text-center">
                    <button 
                        className="btn btn-info btn-block"
                        onClick={() => history.push("/menu")}
                    >Selecionar Região Anatômica</button>
                </div>
            </div>
        </Container>
    )
}