import { useHistory } from 'react-router-dom'
import { Title } from '../../../../components'
import {
    Container
} from './styles'

export default function (){
    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Para os exames de adultos os valores entre 1.5 a 3mm de espessura e espaçamento são bem
aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2.5mm de
espessura e espaçamento.
                        </p>
                        <p>
                        O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 36 a
42cm os mais habituais. 
                        </p>
                        <p>
                        As reformatações curvas tem grande valor informativo, sendo feitas como rotina em muitos
serviços quando houver calculo uretral. 
                        </p>

                        <p className="text-center font-weight-bold">
                        Reformatação da Pelve com contraste I.V.
                        </p>

                        <p className="text-center">
                        Utilizar o Volume S/C e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial  S/C Filme</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>24 imagens</b> <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal S/C</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm<br/>
                            <b>Averege (média)</b>
                        </p>

                        <p className="text-center">
                        Utilizar o Volume fase venosa e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Coronal C/C Fase Venosa</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital C/C Fase Venosa</b><br/>
                            <b>Espessura: </b> 2.5mm<br/>
                            <b>Espaçamento: </b> 2.5mm <br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Axial C/C filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>24 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Sagital C/C filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>

                        <p>
                            <b>Coronal C/C filme</b>
                            <b>Espessura: </b>2.5mm<br/>
                            <b>20 Imagens </b><br/>
                            <b>Averege (média)</b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}