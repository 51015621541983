import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Limites para aquisição das imagens pré contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Os cortes devem ser programados seguindo a orientação da superior para a inferior, deixar
como margem de segurança 3cm acima da crista ilíaca e 3cm abaixo do trocanter menor
conforme ilustração abaixo.
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}