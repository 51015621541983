import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento Sem Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                            Para os exames de adultos os valores entre 1 a 2.5 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2 mm de espessura e espaçamento.<br/>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 14 a 18 cm os mais habituais. <br/>
                            As reformatações tridimensionais tem grande valor informativo, sendo feitas como rotina em muitos serviços. 
                        </p>
                        

                        <p className="text-center">
                        Reformatação da coluna torácica sem contraste I.V. (avaliação do disco intervertebral)<br/>
                        Utilizar o Volume Osso e fazer a seguinte reformatação:
                        </p>

                        <p>
                            <b>Axial Osso T1-T2</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T2-T3</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital Osso T3-T4</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T4-T5</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T5-T6 </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital Osso T6-T7</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T7-T8</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T8-T9</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital Osso T9-T10</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T10-T11</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial Osso T11-T12 </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital Osso</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital Osso filme</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Coronal Osso </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        

                        <p className="text-center">
                            Utilizar o Volume P.M. (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <b>Axial P.M. T1-T2</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T2-T3</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M. T3-T4</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T4-T5</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T5-T6 </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M. T6-T7</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T7-T8</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T8-T9</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M. T9-T10</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T10-T11</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Axial P.M. T11-T12 </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M.</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Sagital P.M. filme</b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        <p>
                            <b>Coronal P.M. </b><br/>
                            <b>Espessura: </b>2mm<br/>
                            <b>Espaçamento: </b>2mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <ul>
                            <li>Fazer 12 imagens em 3D Ósseo Volume Redering da coluna torácica girando da direita para esquerda, salvar como 3D Coluna Torácica Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D Ósseo Volume Redering da coluna torácica girando da superior para inferior, salvar como 3D Coluna Torácica Sup. / Inf.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}