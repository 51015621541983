import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/punho/img2.png'
import img3 from '../../../assets/punho/img3.png'
import img4 from '../../../assets/punho/img4.png'
import img5 from '../../../assets/punho/img5.png'
import img6 from '../../../assets/punho/img6.png'
import img7 from '../../../assets/punho/img7.png'
import img8 from '../../../assets/punho/img8.png'
import img9 from '../../../assets/punho/img9.png'
import img10 from '../../../assets/punho/img10.png'
import img11 from '../../../assets/punho/img11.png'
import img12 from '../../../assets/punho/img12.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da proximal para distal,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares aos
ossos do carpo, utilizar o plano sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início 1cm anterior aos
ossos do carpo e término 1cm posterior aos ossos do carpo.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início 1cm anterior aos ossos do carpo e término
1cm posterior aos ossos do carpo.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os ossos do carpo como referência para angulação, deixar os cortes paralelos a
ele, utilizar imagem no plano sagital como referência. No plano axial deixar os cortes paralelos
aos ossos do carpo, utilizar imagem axial como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura do dorso do punho e término logo após o a musculatura anterior do punho.
                        </p>

                       

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up, os cortes coronais Osso devem ter início antes da musculatura do dorso do punho
e término logo após o a musculatura anterior do punho.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. (sem e com contraste I.V) devem ter início dos ossos do
carpo (dorso do punho) e término logo após o fim dos ossos do carpo.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso devem ter início dos ossos do carpo (dorso do punho) e
término logo após o fim dos ossos do carpo.
                        </p>

                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar os ossos do carpo como referência para angulação, utilizar imagem no plano coronal
deixando os cortes paralelos ao maior eixo do punho. No plano Axial deixar os cortes
perpendiculares aos ossos do carpo (maior eixo), utilizar imagem axial como referência.<br/>
Para back-up, os cortes sagitais P.M. (sem e com contraste I.V.) do punho direito devem ter
início antes da pele da face medial do punho e término logo após o final da pele da face lateral do
punho. O punho esquerdo é o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up, os cortes sagitais Osso do punho direito devem ter início antes da pele da face
medial do punho e término logo após o final da pele da face lateral do punho. O punho esquerdo é
o contrário.
                        </p>

                   
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. (sem e com contraste I.V.) do punho direito devem ter início
antes dos ossos do carpo e término logo após o final dos ossos do carpo. O punho esquerdo é o
contrário.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso do punho direito devem ter início antes dos ossos do carpo e
término logo após o final dos ossos do carpo. O punho esquerdo é o contrário.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        

                        <p className="text-center">
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo do
punho de acordo com a indicação.<br/><br/>
3D VR
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D VR Ósseo + tendões é útil em demonstrar anatomia óssea, fraturas e tendões.</li>
                            <li>3D VR Partes moles é útil em demonstrar anatomia e patologias em partes moles.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}