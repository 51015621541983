import React from 'react'
import {Container} from './styles.js'
import Title from '../../../components/Title'
import { useHistory } from 'react-router'

export default function(){
    const history = useHistory()
    return (
        <Container>
            <div className="row justify-content-center">
                <div className="col-10 text-center">
                    <Title>Escolha uma opção</Title>

                    <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                            history.push("/cranio/backupContraste")
                        }}
                    >Com Contraste I.V.</button>

                    <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                            history.push("/cranio/backup")
                        }}
                    >Sem Contraste I.V.</button>
                </div>
            </div>
        </Container>
    )  
}