import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-Up</Title>

                    <section className="mt-2 p-3">

                        <ul>
                            <li>Scout;</li>
                            <li>Axial P.M. Boca Fechada bilateral;</li>
                            <li>Axial Osso Boca Fechada bilateral;</li>
                            <li>Axial P.M. Boca Aberta bilateral;</li>
                            <li>Axial Osso Boca Aberta bilateral;</li>
                            <li>Sagital P.M. Boca Fechada ATM Direita;</li>
                            <li>Sagital P.M. Boca Fechada ATM Esquerda;</li>
                            <li>Sagital P.M. Boca Aberta ATM Direita;</li>
                            <li>Sagital P.M. Boca Aberta ATM Esquerda;</li>
                            <li>Sagital Osso Boca Fechada ATM Direita;</li>
                            <li>Sagital Osso Boca Fechada ATM Esquerda;</li>
                            <li>Sagital Osso Boca Aberta ATM Direita;</li>
                            <li>Sagital Osso Boca Aberta ATM Esquerda;</li>
                            <li>Coronal P.M. Boca Fechada ATM Direita;</li>
                            <li>Coronal P.M. Boca Fechada ATM Esquerda;</li>
                            <li>Coronal Osso Boca Fechada ATM Direita;</li>
                            <li>Coronal Osso Boca Fechada ATM Esquerda;</li>
                            <li>3D VR ATMs Boca Fechada Dir. / Esq.;</li>
                            <li>3D VR ATMs Boca Fechada Sup. / Inf.;</li>
                            <li>3D VR ATMs Boca Aberta Dir. / Esq.;</li>
                            <li>3D VR ATMs Boca Aberta Sup. / Inf.;</li>
                            <li>Relatório de dose;</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}