import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/abdomenContraste/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Realização e Programação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Scout: </b>Scout AP (0 grau)<br/>
                            <b>mA: </b>10<br/>
                            <b>Início: </b>S150<br />
                            <b>Fim: </b>I300
                        </p>

                        <p>
                            <b>Scout: </b>Scout Perfil (90 graus) <br/>
                            <b>mA: </b>20<br/>
                            <b>Início: </b>S150<br />
                            <b>Fim: </b>I300
                        </p>
                    </section>

                    <Title>Limites para aquisição das imagens pré contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Os cortes devem ser programados seguindo a orientação da superior para a inferior, deixar
como margem de segurança 3cm acima do músculo diafragma e 2cm abaixo da crista ilíaca
conforme ilustração abaixo.
                        </p>
                    </section>

                    <Title>Scout em AP e Perfil com linhas de referência.</Title>
                    
                    <section className="mt-2 p-3">
                        <img className="img-fluid" src={img} alt="Linhas Referencia" />
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Descrição da série axial sem contraste I.V. (aquisição).</h6>

                        <p>
                            <strong>Recon:</strong> Recon 1 <br />
                        
                            <strong>Anotação:</strong> Axial sem contraste I.V.<br />
                        
                            <strong>Espessura:</strong> 2.5 / 2.5mm<br />
                            
                            <strong>Filtro:</strong> Std<br />
                        </p>

                        <p>
                            <strong>Recon:</strong> Recon 2 <br />
                        
                            <strong>Anotação:</strong> Volume P.M.<br />
                        
                            <strong>Espessura:</strong> 1.25 / 1mm<br />
                            
                            <strong>Filtro:</strong> Soft<br />
                        </p>

                    </section>

                    <Title>Limites para aquisição das imagens com contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            A programação da fase arterial, portal e de equilíbrio são idênticas a fase sem contraste I.V.
                        </p>
                        
                    </section>

                    
                </div>
            </div>
        </Container>
    )
}