import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/colunaCervical/img2.PNG'
import img3 from '../../../assets/colunaCervical/img3.PNG'
import img4 from '../../../assets/colunaCervical/img4.PNG'
import img5 from '../../../assets/colunaCervical/img5.PNG'
import img6 from '../../../assets/colunaCervical/img6.PNG'
import img7 from '../../../assets/colunaCervical/img7.PNG'
import img8 from '../../../assets/colunaCervical/img8.PNG'
import img9 from '../../../assets/colunaCervical/img9.PNG'
import img10 from '../../../assets/colunaCervical/img10.PNG'
import img11 from '../../../assets/colunaCervical/img11.PNG'
import img12 from '../../../assets/colunaCervical/img12.PNG'
import img13 from '../../../assets/colunaCervical/img13.PNG'
import img14 from '../../../assets/colunaCervical/img14.PNG'
import img15 from '../../../assets/colunaCervical/img15.PNG'
import img16 from '../../../assets/colunaCervical/img16.PNG'
import img17 from '../../../assets/colunaCervical/img17.PNG'
import img18 from '../../../assets/colunaCervical/img18.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
utilizar o disco intervertebral como referência para angulação para avaliação dos discos
intervertebrais ou deixar perpendicular a coluna cervical em casos de trauma ou tumores,
selecionar imagem no plano sagital como referência. Caso a coluna esteja girada, corrigir no
plano coronal.<br/>
Para back-up e filme, os cortes axiais P.M. (sem ou com contraste I.V.) para avaliação dos
discos intervertebrais devem ter início logo acima do forame intervertebral e término logo após o
final do forame intervertebral de modo que o primeiro e o último corte mostrem os pedículos
vertebrais bilateralmente conforme ilustração abaixo.
                        </p>

                        {/* <p className="text-center">
                            Imagem Axial obliquo               Ref. Coronal                Ref. Sagital
                        </p> */}

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Fazer as reformatações nos espaços Intervertebrais de C2 a C7. Para facilitar a identificação,
nomear as séries de acordo com o espaço intervertebral, tipo de filtro utilizado e meio de
contraste I.V. quando utilizado.
                        </p>
                        <img className="img-fluid" src={img3} alt="Reformatacoes" />
                        <img className="img-fluid" src={img4} alt="Reformatacoes" />



                        <p>
                        Para back-up e filme, os cortes axiais Osso para avaliação dos discos e espaços intervertebrais
devem ter início logo acima do forame intervertebral e término logo após o final do forame
intervertebral de modo que o primeiro e o último corte mostrem os pedículos vertebrais
bilateralmente.<br/>
Fazer as reformatações nos espaços Intervertebrais de C2 a C7. Para facilitar a identificação,
nomear as séries de acordo com o espaço discal, tipo de filtro utilizado.
                        </p>

                        <img className="img-fluid" src={img5} alt="Reformatacoes" />
                        <img className="img-fluid" src={img6} alt="Reformatacoes" />
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais P.M. (sem ou com contraste I.V.) para avaliação de trauma ou
tumor devem ter início logo acima de C1 e término logo após o final de C7.
                        </p>
                        
                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso para avaliação de trauma ou tumor devem ter início logo
acima de C1 e término logo após o final de C7.
                        </p>

                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar imagem no plano sagital como referência, deixar os cortes perpendiculares a
coluna cervical. Caso a esta esteja girada corrigir no plano axial.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da pele
e término 1 cm anterior a coluna cervical.
                        </p>
                        
                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da pele e término 1 cm anterior a
coluna cervical.
                        </p>


                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. (sem e com contraste I.V) devem ter início no processo
espinhoso e término no início da coluna cervical.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso devem ter início no processo espinhoso e término no início
da coluna cervical.
                        </p>

                        {/* <p className="text-center">
                        Imagem Sagital P.M.             Ref. Axial              Ref. Coronal
                        </p> */}

                        <img className="img-fluid" src={img13} alt="Reformatacoes" />

                        <p>Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar a coluna cervical como referência para angulação, utilizar imagem no plano coronal como
referência. Caso a coluna esteja girada corrigir no plano axial.
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início antes do
processo transverso esquerdo e término logo após o final do processo transverso direito.</p>

                        <img className="img-fluid" src={img14} alt="Reformatacoes" />
                        <p>
                        Para back-up os cortes sagitais Osso devem ter início antes do processo transverso esquerdo e
término logo após o final do processo transverso direito.
                        </p>
                        <img className="img-fluid" src={img15} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes sagitais P.M. (sem ou com contraste I.V) devem ter início a 0,5cm do corpo
vertebral a esquerda e término a 0,5cm do corpo vertebral a direita.
                        </p>
                        <img className="img-fluid" src={img16} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes sagitais osso devem ter início a 0,5cm do corpo vertebral a esquerda e término a
0,5cm do corpo vertebral a direita.
                        </p>
                        <img className="img-fluid" src={img17} alt="Reformatacoes" />

                        <p className="text-center">
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da coluna cervical de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img18} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}