import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/mao/img2.png'
import img3 from '../../../assets/mao/img3.png'
import img4 from '../../../assets/mao/img4.png'
import img5 from '../../../assets/mao/img5.png'
import img6 from '../../../assets/mao/img6.png'
import img7 from '../../../assets/mao/img7.png'
import img8 from '../../../assets/mao/img8.png'
import img9 from '../../../assets/mao/img9.png'
import img10 from '../../../assets/mao/img10.png'
import img11 from '../../../assets/mao/img11.png'
import img12 from '../../../assets/mao/img12.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes perpendiculares aos
metacarpos, utilizar o plano coronal e sagital.<br/>
Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo abaixo da
base dos metatarsos e término logo abaixo das falanges distais.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo abaixo da base dos metatarsos e
término logo abaixo das falanges distais.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar os metacarpos como referência para angulação, deixar os cortes paralelos a
eles, utilizar imagem no plano sagital como referência. No plano axial deixar os cortes paralelos
metacarpos ou falanges, utilizar imagem axial como referência.<br/>
Para back-up os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da
musculatura posterior e término logo após o a musculatura anterior da mão.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início antes da musculatura posterior e término
logo após o a musculatura anterior da mão.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início antes dos
ossos do carpo e término logo após a musculatura posterior da mão.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso filme devem ter início antes dos ossos do carpo e término
logo após a musculatura posterior da mão.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar os metacarpos / falanges como referência para angulação, deixando os cortes paralelos a
estes, utilizar imagem no plano coronal como referência. No plano axial deixar os cortes
perpendiculares aos metacarpos / falanges, utilizar imagem axial como referência.<br/>
Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) da mão direita devem ter início
antes da musculatura medial do quinto dedo e término logo após o final da musculatura do
primeiro dedo e da mão esquerda o contrário.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso da mão direita devem ter início antes da musculatura
medial do quinto dedo e término logo após o final da musculatura do primeiro dedo e da mão
esquerda o contrário.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) da mão devem ter início
anterior ao metacarpo de interesse e termino logo após o metacarpo de interesse.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso filme da mão devem ter início anterior ao metacarpo de
interesse e termino logo após o metacarpo de interesse.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da mão de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />


                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas. 3D VR MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}