import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Objetivo</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>
                        O objetivo deste projeto é elaborar um aplicativo de consulta de
                        protocolos para pós-processamento de imagens em tomografia computadorizada
                        dos principais exames realizados na rotina clínica deixando os exames
                        angiográficos para um outro momento. Que este seja ricamente ilustrado e
                        explicativo, com textos e imagens citando os limites de aquisição das imagens,
                        com os parâmetros técnicos utilizados, imagens de referência para elucidar os
                        textos explicando os limites anatômicos a serem utilizados e modelos de
                        reconstruções tridimensionais adequados a região a ser examinada.<br/><br/>
                        A elaboração deste aplicativo é de extrema relevância para padronização
                        dos procedimentos de pós-processamento de imagens, para um diagnóstico preciso
                        e precoce das lesões teciduais, servindo de referência para formulação e
                        padronização de protocolos de pós-processamento em tomografia
                        computadorizada.<br/><br/>
                        As sugestões de protocolos aqui apresentadas são baseadas em protocolos
                        clínicos utilizados em grandes centros de imagens e na vivência dos autores não
                        devendo ser utilizadas como regra absoluta, são sugestões visando um consenso na
                        diversidade de fabricantes, equipamentos e suas características.<br/><br/>
                        Este aplicativo foi elaborado e apresentado à Universidade do Vale do Sapucaí - UNIVÁS, como parte do trabalho final para a obtenção do título de Mestre em Ciências Aplicadas à Saúde.
                    </p>
                </div>
            </div>
        </Container>
    )
}