import { useState } from "react";
import { Title } from "../../components";
import { Container } from "./styles";


function PPPSemContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolos de pós-processamento de imagens</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Reformatações:</h6>

                        <p>
                            Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2 mm de espessura e espaçamento.
                        </p>

                        <p>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 14 a 20 cm os mais habituais.
                        </p>

                        <p>
                            As reformatações tridimensionais tem grande valor informativo, sendo feitas como rotina em muitos serviços.
                        </p>

                        <p className="text-center">
                        Utilizar o Volume P.M. (partes moles) e fazer as seguintes reformatações:
                        </p>

                        <ul>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering do joelho girando da direita para esquerda, salvar como 3D VR Joelho Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering do joelho girando da superior para inferior, salvar como 3D VR Joelho Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo do joelho girando da direita para esquerda, salvar como 3D MIP Joelho Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo do joelho girando da superior para inferior, salvar como 3D MIP Joelho Sup. / Inf.</li>
                        </ul>
                    </section>

                    <section className="mt-2 p-3">
                        <p>
                            <strong>Sagital P.M.L.C.A.</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal P.M.</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital P.M.</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <p>
                            <strong>Axial P.M. filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal P.M. filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital P.M. filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <p className="font-weight-bold text-center">Utilizar o Volume Osso e fazer as seguintes reformatações:</p>

                        <p>
                            <strong>Axial Osso filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital Osso</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Osso</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                </div>
            </div>
        </Container>
    )
}

function PPPContraste(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolos de pós-processamento de imagens</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Reformatações:</h6>

                        <p>
                            Para os exames de adultos os valores entre 1 a 3 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 1 a 2 mm de espessura e espaçamento.
                        </p>

                        <p>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 14 a 20 cm os mais habituais.
                        </p>

                        <p>
                            As reformatações tridimensionais tem grande valor informativo, sendo feitas como rotina em muitos serviços.
                        </p>

                        <p className="text-center">
                            Utilizar o Volume Osso e fazer as seguintes reformatações:
                        </p>

                        <p>
                            <strong>Axial Osso Filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital Osso</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal Osso</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p className="text-center">Utilizar o Volume P.M (partes moles) sem contraste I.V. e fazer as seguintes reformatações:</p>

                        <ul>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering do joelho girando da direita para esquerda, salvar como 3D VR Joelho Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D VR Ósseo Volume Redering do joelho girando da superior para inferior, salvar como 3D VR Joelho Sup. / Inf.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo do joelho girando da direita para esquerda, salvar como 3D MIP Joelho Dir. / Esq.</li>
                            <li>Fazer 12 imagens em 3D MIP Ósseo do joelho girando da superior para inferior, salvar como 3D MIP Joelho Sup. / Inf.</li>
                        </ul>
                    </section>

                    <section className="mt-2 p-3">
                        <p>
                            <strong>Axial P.M. S/C filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal P.M. S/C</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital P.M. S/C</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Utilizar o Volume P.M. (partes moles) com contraste I.V. e fazer as seguintes reformatações:</h6>
                        <p>
                            <strong>Axial P.M. C/C filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens </strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Coronal P.M. C/C</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital P.M. C/C</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>Espaçamento: </strong>1.5mm<br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                    <section className="mt-2 p-3">

                        <p>
                            <strong>Coronal P.M. C/C filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>

                        <p>
                            <strong>Sagital P.M. C/C filme</strong><br />
                            <strong>Espessura: </strong>1.5mm<br />
                            <strong>20 imagens</strong><br />
                            <strong>Averege (média)</strong><br />
                        </p>
                    </section>

                </div>
            </div>
        </Container>
    )
}

export default function ProtocolosPosProcessamento(){
    const [contraste, setContraste] = useState(null)

    if(contraste === true){
        return PPPContraste()
    }else if (contraste === false){
        return PPPSemContraste()
    }else{
        return (
            <Container>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <Title>Escolha uma opção</Title>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(true)
                            }}
                        >Com Contraste I.V.</button>
    
                        <button
                            className="btn btn-info btn-block"
                            onClick={() => {
                                setContraste(false)
                            }}
                        >Sem Contraste I.V.</button>
                    </div>
                </div>
            </Container>
        )
    }
}