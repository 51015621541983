import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Posição do Paciente</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Decúbito: </b>Supino<br/>
                            <b>Orientação do paciente: </b>Cabeça primeiro<br/>
                            <b>Ponto Zero: </b>Zerar no meio do antebraço<br />
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}