import { Title } from "../../../components";
import { Container } from "./styles";

import img from '../../../assets/articulacoes/img1.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Protocolo de pós-processamento com Contraste</Title>

                    <section className="mt-2 p-3">
                        <p className="text-center">Reformatações:</p>

                        <p>
                            Para os exames de adultos os valores entre 0,6 a 1 mm de espessura e espaçamento são bem aceitos na grande maioria dos serviços e para uso pediátrico valores ente 0,6 a 1 mm de espessura e espaçamento.<br/>
                            O FOV deve ser o menor possível se adequando a cada paciente sendo os valores entre 22 a 24 cm os mais habituais para estudo bilateral e 9 a 12 cm para o estudo unilateral. 
                        </p>

                        <p className="text-center">
                            Reformatação das mastoides / ouvidos com contraste I.V. <br/>
                            Utilizar as séries axial Osso direito e esquerdo e fazer a seguinte reformatação:
                        </p>

                        <p>
                            <b>Axial Osso direito filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Esquerdo</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial Osso esquerdo filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Esquerdo filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal Osso Direito filme </b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital Osso esquerdo</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>


                        <p className="text-center">
                            Utilizar as séries axiais P.M. sem contraste direito e esquerdo e fazer a seguinte reformatação:
                        </p>

                        <p>
                            <b>Coronal P.M. S/C Direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. S/C Esquerdo</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>
                        
                        <p>
                            <b>Sagital P.M. S/C direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b> 1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. S/C esquerdo</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b> 1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            Utilizar as séries axiais P.M. com contraste direito e esquerdo e fazer a seguinte reformatação:
                        </p>

                        <p>
                            <b>Coronal P.M. C/C Direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Coronal P.M. C/C Esquerdo</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial P.M. C/C direito filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                        
                        <p>
                            <b>Sagital P.M. C/C direito</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Sagital P.M. C/C esquerdo </b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>Espaçamento: </b>1mm<br/>
                            <b>Averege (Média): </b>
                        </p>

                        <p>
                            <b>Axial P.M. C/C esquerdo filme</b><br/>
                            <b>Espessura: </b>1mm<br/>
                            <b>20 Imagens</b><br/>
                            <b>Averege (Média): </b>
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}