import { 
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {
  BackButton,
  Header
} from "./components";
import {
  Menu,
  Home,
  AIJoelho,
  BKJoelho,
  DOCJoelho,
  Joelho,
  PPPJoelho,
  PTJoelho,
  RAJoelho,
  AITorax,
  BKTorax,
  DOCTorax,
  Torax,
  PPPTorax,
  PTTorax,
  RATorax,
  PPTorax,
  LRTorax,
  RPTorax,
  Tronco,
  Abdomen,
  AbdomenContraste,
  Pelve,
  Rins,
  RinsContraste,
  ToraxAR,
  AIAbdomen,
  ACAbdomen,
  LRAbdomen,
  LCAbdomen,
  PTAbdomen,
  PPAbdomen,
  RPAbdomen,
  RMAbdomen,
  RFAbdomen,
  BKAbdomen,
  DCAbdomen,
  AIAbdomenCont,
  ACAbdomenCont,
  LRAbdomenCont,
  LCAbdomenCont,
  PTAbdomenCont,
  PPAbdomenCont,
  RPAbdomenCont,
  RMAbdomenCont,
  BKAbdomenCont,
  DCAbdomenCont,
  RFAbdomenCont,
  AIRinsCont,
  ACRinsCont,
  LRRinsCont,
  LCRinsCont,
  PTRinsCont,
  PPRinsCont,
  RPRinsCont,
  RMRinsCont,
  BKRinsCont,
  DCRinsCont,
  RFRinsCont,
  AIRins,
  LRRins,
  PTRins,
  PPRins,
  RPRins,
  RMRins,
  BKRins,
  DCRins,
  RFRins,
  AIPelve,
  ACPelve,
  LRPelve,
  PTPelve,
  PPPelve,
  RPPelve,
  RMPelve,
  BKPelve,
  DCPelve,
  RFPelve,
  AIToraxAR,
  LRToraxAR,
  PTToraxAR,
  PPToraxAR,
  RPToraxAR,
  RMToraxAR,
  BKToraxAR,
  DCToraxAR,
  RFToraxAR,
  APBkp,
  APBkpCont,
  APDoc,
  APDocCont,
  APPTe,
  APPPa,
  APPos,
  APPosCont,
  APProg,
  APRef,
  ARBkp,
  ARBkpCont,
  ARDoc,
  ARDocCont,
  ARPTe,
  ARPPa,
  ARPos,
  ARPosCont,
  ARProg,
  ARRef,
  BaBkp,
  BaBkpCont,
  BaDoc,
  BaDocCont,
  BaPTe,
  BaPPa,
  BaPos,
  BaPosCont,
  BaProg,
  BaRef,
  CoBkp,
  CoBkpCont,
  CoDoc,
  CoDocCont,
  CoPTe,
  CoPPa,
  CoPos,
  CoPosCont,
  CoProg,
  CoRef,
  JoBkp,
  JoBkpCont,
  JoDoc,
  JoDocCont,
  JoPTe,
  JoPPa,
  JoPos,
  JoPosCont,
  JoProg,
  JoRef,
  PeBkp,
  PeBkpCont,
  PeDoc,
  PeDocCont,
  PePTe,
  PePPa,
  PePos,
  PePosCont,
  PeProg,
  PeRef,
  QuBkp,
  QuBkpCont,
  QuDoc,
  QuDocCont,
  QuPTe,
  QuPPa,
  QuPos,
  QuPosCont,
  QuProg,
  QuRef,
  ToBkp,
  ToBkpCont,
  ToDoc,
  ToDocCont,
  ToPTe,
  ToPPa,
  ToPos,
  ToPosCont,
  ToProg,
  ToRef,
  AntePe,
  Articulacoes,
  Bacia,
  Coxa,
  JoelhoMI,
  Perna,
  Quadril,
  Tornozelo,
  MembrosInferiores,
  APBkpMenu,
  ARBkpMenu,
  BaBkpMenu,
  CoBkpMenu,
  JoBkpMenu,
  PeBkpMenu,
  QuBkpMenu,
  ToBkpMenu,
  APDocMenu,
  ARDocMenu,
  BaDocMenu,
  CoDocMenu,
  JoDocMenu,
  PeDocMenu,
  QuDocMenu,
  ToDocMenu,
  APPosMenu,
  ARPosMenu,
  BaPosMenu,
  CoPosMenu,
  JoPosMenu,
  PePosMenu,
  QuPosMenu,
  ToPosMenu,
  MembrosSuperiores,
  Antebraco,
  ABBkp,
  ABBkpMenu,
  ABBkpCont,
  ABDoc,
  ABDocMenu,
  ABDocCont,
  ABPTe,
  ABPPa,
  ABPos,
  ABPosMenu,
  ABPosCont,
  ABProg,
  ABRef,
  Braco,
  BRBkp,
  BRBkpMenu,
  BRBkpCont,
  BRDoc,
  BRDocMenu,
  BRDocCont,
  BRPTe,
  BRPPa,
  BRPos,
  BRPosMenu,
  BRPosCont,
  BRProg,
  BRRef,
  Cotovelo,
  CTBkp,
  CTBkpMenu,
  CTBkpCont,
  CTDoc,
  CTDocMenu,
  CTDocCont,
  CTPTe,
  CTPPa,
  CTPos,
  CTPosMenu,
  CTPosCont,
  CTProg,
  CTRef,
  Mao,
  MABkp,
  MABkpMenu,
  MABkpCont,
  MADoc,
  MADocMenu,
  MADocCont,
  MAPTe,
  MAPPa,
  MAPos,
  MAPosMenu,
  MAPosCont,
  MAProg,
  MARef,
  Ombro,
  OBBkp,
  OBBkpMenu,
  OBBkpCont,
  OBDoc,
  OBDocMenu,
  OBDocCont,
  OBPTe,
  OBPPa,
  OBPos,
  OBPosMenu,
  OBPosCont,
  OBProg,
  OBRef,
  Punho,
  PUBkp,
  PUBkpMenu,
  PUBkpCont,
  PUDoc,
  PUDocMenu,
  PUDocCont,
  PUPTe,
  PUPPa,
  PUPos,
  PUPosMenu,
  PUPosCont,
  PUProg,
  PURef,
  Coluna,
  ColunaCervical,
  CCBkp,
  CCBkpMenu,
  CCBkpCont,
  CCBkpTrau,
  CCBkpDisc,
  CCDoc,
  CCDocMenu,
  CCDocCont,
  CCDocDisc,
  CCDocTrau,
  CCPTe,
  CCPPa,
  CCPos,
  CCPosMenu,
  CCPosCont,
  CCProg,
  CCRef,
  ColunaLombar,
  CLBkp,
  CLBkpMenu,
  CLBkpCont,
  CLBkpTrau,
  CLBkpDisc,
  CLDoc,
  CLDocMenu,
  CLDocCont,
  CLDocDisc,
  CLDocTrau,
  CLPTe,
  CLPPa,
  CLPos,
  CLPosMenu,
  CLPosCont,
  CLProg,
  CLRef,
  ColunaToracica,
  CRBkp,
  CRBkpMenu,
  CRBkpCont,
  CRBkpTrau,
  CRBkpDisc,
  CRDoc,
  CRDocMenu,
  CRDocCont,
  CRDocDisc,
  CRDocTrau,
  CRPTe,
  CRPPa,
  CRPos,
  CRPosMenu,
  CRPosCont,
  CRProg,
  CRRef,
  CRPosTrau,
  CRPosDisc,
  CLPosTrau,
  CLPosDisc,
  CCPosTrau,
  CCPosDisc,
  Cabeca,
  Atm,
  AtBkp,
  AtBkpMenu,
  AtBkpCont,
  AtDoc,
  AtDocMenu,
  AtDocCont,
  AtPTe,
  AtPPa,
  AtPos,
  AtPosMenu,
  AtPosCont,
  AtProg,
  AtRef,
  Cranio,
  CNBkp,
  CNBkpMenu,
  CNBkpCont,
  CNDoc,
  CNDocMenu,
  CNDocCont,
  CNPTe,
  CNPPa,
  CNPos,
  CNPosMenu,
  CNPosCont,
  CNProg,
  CNRef,
  Face,
  FCBkp,
  FCBkpMenu,
  FCBkpCont,
  FCDoc,
  FCDocMenu,
  FCDocCont,
  FCPTe,
  FCPPa,
  FCPos,
  FCPosMenu,
  FCPosCont,
  FCProg,
  FCRef,
  Hipofise,
  HFBkp,
  HFBkpMenu,
  HFBkpCont,
  HFDoc,
  HFDocMenu,
  HFDocCont,
  HFPTe,
  HFPPa,
  HFPos,
  HFPosMenu,
  HFPosCont,
  HFProg,
  HFRef,
  Orbitas,
  ORBkp,
  ORBkpMenu,
  ORBkpCont,
  ORDoc,
  ORDocMenu,
  ORDocCont,
  ORPTe,
  ORPPa,
  ORPos,
  ORPosMenu,
  ORPosCont,
  ORProg,
  ORRef,
  Ouvidos,
  OUBkp,
  OUBkpMenu,
  OUBkpCont,
  OUDoc,
  OUDocMenu,
  OUDocCont,
  OUPTe,
  OUPPa,
  OUPos,
  OUPosMenu,
  OUPosCont,
  OUProg,
  OURef,
  Pescoco,
  PEBkp,
  PEBkpMenu,
  PEBkpCont,
  PEDoc,
  PEDocMenu,
  PEDocCont,
  PEPTe,
  PEPPa,
  PEPos,
  PEPosMenu,
  PEPosCont,
  PEProg,
  PERef,
  SeiosFace,
  SFBkp,
  SFBkpMenu,
  SFBkpCont,
  SFDoc,
  SFDocMenu,
  SFDocCont,
  SFPTe,
  SFPPa,
  SFPos,
  SFPosMenu,
  SFPosCont,
  SFProg,
  SFRef,
  Sumario,
  Autores,
  Contato,
  Desenvolvedor,
  Objetivo,
  Orientacoes,
  Referencias
} from "./pages";

export default function App() {
  return (
    <div>
      <Header />
      <Router>
        <Switch>
          <Route path="/Autores" exact>
            <Autores/>
          </Route>
          <Route path="/Contato" exact>
            <Contato/>
          </Route>
          <Route path="/Desenvolvedor" exact>
            <Desenvolvedor/>
          </Route>
          <Route path="/Objetivo" exact>
            <Objetivo/>
          </Route>
          <Route path="/Orientacoes" exact>
            <Orientacoes/>
          </Route>
          <Route path="/Referencias" exact>
            <Referencias/>
          </Route>
          <Route path="/Sumario" exact>
            <Sumario/>
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/menu" exact>
            <Menu />
          </Route>
          <Route path="/joelho" exact>
            <Joelho />
          </Route>

          <Route path="/joelho/parametrosTecnicos" exact>
            <PTJoelho />
          </Route>

          <Route path="/joelho/aquisicaoImagens" exact>
            <AIJoelho />
          </Route>

          <Route path="/joelho/referenciasAnatomicas" exact>
            <RAJoelho />
          </Route>

          <Route path="/joelho/protocolosPosProcessamento" exact>
            <PPPJoelho />
          </Route>

          <Route path="/joelho/backup" exact>
            <BKJoelho />
          </Route>

          <Route path="/joelho/documentacao" exact>
            <DOCJoelho />
          </Route>

          <Route path="/tronco" exact>
            <Tronco />
          </Route>

          <Route path="/tronco/torax" exact>
            <Torax />
          </Route>

          <Route path="/tronco/Abdomen" exact>
            <Abdomen />
          </Route>
          <Route path="/tronco/AbdomenContraste" exact>
            <AbdomenContraste />
          </Route>
          <Route path="/tronco/Pelve" exact>
            <Pelve />
          </Route>
          <Route path="/tronco/Rins" exact>
            <Rins />
          </Route>
          <Route path="/tronco/RinsContraste" exact>
            <RinsContraste />
          </Route>
          <Route path="/tronco/ToraxAR" exact>
            <ToraxAR />
          </Route>

          <Route path="/tronco/torax/parametrosTecnicos" exact>
            <PTTorax />
          </Route>

          <Route path="/tronco/torax/posicaoPaciente" exact>
            <PPTorax />
          </Route>

          <Route path="/tronco/torax/linhasReferencia" exact>
            <LRTorax />
          </Route>

          <Route path="/tronco/torax/realizacaoProgramacao" exact>
            <RPTorax />
          </Route>

          <Route path="/tronco/torax/aquisicaoImagens" exact>
            <AITorax />
          </Route>

          <Route path="/tronco/torax/referenciasAnatomicas" exact>
            <RATorax />
          </Route>

          <Route path="/tronco/torax/protocolosPosProcessamento" exact>
            <PPPTorax />
          </Route>

          <Route path="/tronco/torax/backup" exact>
            <BKTorax />
          </Route>

          <Route path="/tronco/torax/documentacao" exact>
            <DOCTorax />
          </Route>


          <Route path="/Tronco/Abdomen/AquisicaoImagens" exact>
            <AIAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/AquisicaoImagensContraste" exact>
            <ACAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/LinhasReferencia" exact>
            <LRAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/LinhasReferenciaArterial" exact>
            <LCAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/ParametrosTecnicos" exact>
            <PTAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/PosicaoPaciente" exact>
            <PPAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/RealizacaoProgramacao" exact>
            <RPAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/ReformatacoesMultiplanares" exact>
            <RMAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/Reformatacoes" exact>
            <RFAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/Reformatacoes/Backup" exact>
            <BKAbdomen />
          </Route>
          <Route path="/Tronco/Abdomen/Reformatacoes/Documentacao" exact>
            <DCAbdomen />
          </Route>
          <Route path="/Tronco/AbdomenContraste/AquisicaoImagens" exact>
            <AIAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/AquisicaoImagensContraste" exact>
            <ACAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/LinhasReferencia" exact>
            <LRAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/LinhasReferenciaContraste" exact>
            <LCAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/ParametrosTecnicos" exact>
            <PTAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/PosicaoPaciente" exact>
            <PPAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/RealizacaoProgramacao" exact>
            <RPAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/ReformatacoesMultiplanares" exact>
            <RMAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/Reformatacoes/Backup" exact>
            <BKAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/Reformatacoes/Documentacao" exact>
            <DCAbdomenCont />
          </Route>
          <Route path="/Tronco/AbdomenContraste/Reformatacoes" exact>
            <RFAbdomenCont />
          </Route>
          <Route path="/Tronco/RinsContraste/AquisicaoImagens" exact>
            <AIRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/AquisicaoImagensContraste" exact>
            <ACRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/LinhasReferencia" exact>
            <LRRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/LinhasReferenciaContraste" exact>
            <LCRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/ParametrosTecnicos" exact>
            <PTRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/PosicaoPaciente" exact>
            <PPRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/RealizacaoProgramacao" exact>
            <RPRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/ReformatacoesMultiplanares" exact>
            <RMRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/Reformatacoes/Backup" exact>
            <BKRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/Reformatacoes/Documentacao" exact>
            <DCRinsCont />
          </Route>
          <Route path="/Tronco/RinsContraste/Reformatacoes" exact>
            <RFRinsCont />
          </Route>
          <Route path="/Tronco/Rins/AquisicaoImagens" exact>
            <AIRins />
          </Route>
          <Route path="/Tronco/Rins/LinhasReferencia" exact>
            <LRRins />
          </Route>
          <Route path="/Tronco/Rins/ParametrosTecnicos" exact>
            <PTRins />
          </Route>
          <Route path="/Tronco/Rins/PosicaoPaciente" exact>
            <PPRins />
          </Route>
          <Route path="/Tronco/Rins/RealizacaoProgramacao" exact>
            <RPRins />
          </Route>
          <Route path="/Tronco/Rins/ReformatacoesMultiplanares" exact>
            <RMRins />
          </Route>
          <Route path="/Tronco/Rins/Reformatacoes/Backup" exact>
            <BKRins />
          </Route>
          <Route path="/Tronco/Rins/Reformatacoes/Documentacao" exact>
            <DCRins />
          </Route>
          <Route path="/Tronco/Rins/Reformatacoes" exact>
            <RFRins />
          </Route>
          <Route path="/Tronco/Pelve/AquisicaoImagensContraste" exact>
            <ACPelve />
          </Route>
          <Route path="/Tronco/Pelve/AquisicaoImagensPreContraste" exact>
            <AIPelve />
          </Route>
          <Route path="/Tronco/Pelve/LinhasReferencia" exact>
            <LRPelve />
          </Route>
          <Route path="/Tronco/Pelve/ParametrosTecnicos" exact>
            <PTPelve />
          </Route>
          <Route path="/Tronco/Pelve/PosicaoPaciente" exact>
            <PPPelve />
          </Route>
          <Route path="/Tronco/Pelve/RealizacaoProgramacao" exact>
            <RPPelve />
          </Route>
          <Route path="/Tronco/Pelve/ReformatacoesMultiplanares" exact>
            <RMPelve />
          </Route>
          <Route path="/Tronco/Pelve/Reformatacoes/Backup" exact>
            <BKPelve />
          </Route>
          <Route path="/Tronco/Pelve/Reformatacoes/Documentacao" exact>
            <DCPelve />
          </Route>
          <Route path="/Tronco/Pelve/Reformatacoes" exact>
            <RFPelve />
          </Route>
          <Route path="/Tronco/ToraxAR/AquisicaoImagens" exact>
            <AIToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/LinhasReferencia" exact>
            <LRToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/ParametrosTecnicos" exact>
            <PTToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/PosicaoPaciente" exact>
            <PPToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/RealizacaoProgramacao" exact>
            <RPToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/ReformatacoesMultiplanares" exact>
            <RMToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/Reformatacoes/Backup" exact>
            <BKToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/Reformatacoes/Documentacao" exact>
            <DCToraxAR />
          </Route>
          <Route path="/Tronco/ToraxAR/Reformatacoes" exact>
            <RFToraxAR />
          </Route>

          <Route path="/AntePe/Backup" exact>
            <APBkp />
          </Route>
          <Route path="/AntePe/BackupContraste" exact>
            <APBkpCont />
          </Route>
          <Route path="/AntePe/Documentacao" exact>
            <APDoc />
          </Route>
          <Route path="/AntePe/DocumentacaoContraste" exact>
            <APDocCont />
          </Route>
          <Route path="/AntePe/ParametrosTecnicos" exact>
            <APPTe />
          </Route>
          <Route path="/AntePe/PosicaoPaciente" exact>
            <APPPa />
          </Route>
          <Route path="/AntePe/PosProcessamento" exact>
            <APPos />
          </Route>
          <Route path="/AntePe/PosProcessamentoContraste" exact>
            <APPosCont />
          </Route>
          <Route path="/AntePe/Programacao" exact>
            <APProg />
          </Route>
          <Route path="/AntePe/Reformatacoes" exact>
            <APRef />
          </Route>
          <Route path="/Articulacoes/Backup" exact>
            <ARBkp />
          </Route>
          <Route path="/Articulacoes/BackupContraste" exact>
            <ARBkpCont />
          </Route>
          <Route path="/Articulacoes/Documentacao" exact>
            <ARDoc />
          </Route>
          <Route path="/Articulacoes/DocumentacaoContraste" exact>
            <ARDocCont />
          </Route>
          <Route path="/Articulacoes/ParametrosTecnicos" exact>
            <ARPTe />
          </Route>
          <Route path="/Articulacoes/PosicaoPaciente" exact>
            <ARPPa />
          </Route>
          <Route path="/Articulacoes/PosProcessamento" exact>
            <ARPos />
          </Route>
          <Route path="/Articulacoes/PosProcessamentoContraste" exact>
            <ARPosCont />
          </Route>
          <Route path="/Articulacoes/Programacao" exact>
            <ARProg />
          </Route>
          <Route path="/Articulacoes/Reformatacoes" exact>
            <ARRef />
          </Route>
          <Route path="/Bacia/Backup" exact>
            <BaBkp />
          </Route>
          <Route path="/Bacia/BackupContraste" exact>
            <BaBkpCont />
          </Route>
          <Route path="/Bacia/Documentacao" exact>
            <BaDoc />
          </Route>
          <Route path="/Bacia/DocumentacaoContraste" exact>
            <BaDocCont />
          </Route>
          <Route path="/Bacia/ParametrosTecnicos" exact>
            <BaPTe />
          </Route>
          <Route path="/Bacia/PosicaoPaciente" exact>
            <BaPPa />
          </Route>
          <Route path="/Bacia/PosProcessamento" exact>
            <BaPos />
          </Route>
          <Route path="/Bacia/PosProcessamentoContraste" exact>
            <BaPosCont />
          </Route>
          <Route path="/Bacia/Programacao" exact>
            <BaProg />
          </Route>
          <Route path="/Bacia/Reformatacoes" exact>
            <BaRef />
          </Route>
          <Route path="/Coxa/Backup" exact>
            <CoBkp />
          </Route>
          <Route path="/Coxa/BackupContraste" exact>
            <CoBkpCont />
          </Route>
          <Route path="/Coxa/Documentacao" exact>
            <CoDoc />
          </Route>
          <Route path="/Coxa/DocumentacaoContraste" exact>
            <CoDocCont />
          </Route>
          <Route path="/Coxa/ParametrosTecnicos" exact>
            <CoPTe />
          </Route>
          <Route path="/Coxa/PosicaoPaciente" exact>
            <CoPPa />
          </Route>
          <Route path="/Coxa/PosProcessamento" exact>
            <CoPos />
          </Route>
          <Route path="/Coxa/PosProcessamentoContraste" exact>
            <CoPosCont />
          </Route>
          <Route path="/Coxa/Programacao" exact>
            <CoProg />
          </Route>
          <Route path="/Coxa/Reformatacoes" exact>
            <CoRef />
          </Route>
          <Route path="/JoelhoMI/Backup" exact>
            <JoBkp />
          </Route>
          <Route path="/JoelhoMI/BackupContraste" exact>
            <JoBkpCont />
          </Route>
          <Route path="/JoelhoMI/Documentacao" exact>
            <JoDoc />
          </Route>
          <Route path="/JoelhoMI/DocumentacaoContraste" exact>
            <JoDocCont />
          </Route>
          <Route path="/JoelhoMI/ParametrosTecnicos" exact>
            <JoPTe />
          </Route>
          <Route path="/JoelhoMI/PosicaoPaciente" exact>
            <JoPPa />
          </Route>
          <Route path="/JoelhoMI/PosProcessamento" exact>
            <JoPos />
          </Route>
          <Route path="/JoelhoMI/PosProcessamentoContraste" exact>
            <JoPosCont />
          </Route>
          <Route path="/JoelhoMI/Programacao" exact>
            <JoProg />
          </Route>
          <Route path="/JoelhoMI/Reformatacoes" exact>
            <JoRef />
          </Route>
          <Route path="/perna/Backup" exact>
            <PeBkp />
          </Route>
          <Route path="/perna/BackupContraste" exact>
            <PeBkpCont />
          </Route>
          <Route path="/perna/Documentacao" exact>
            <PeDoc />
          </Route>
          <Route path="/perna/DocumentacaoContraste" exact>
            <PeDocCont />
          </Route>
          <Route path="/perna/ParametrosTecnicos" exact>
            <PePTe />
          </Route>
          <Route path="/perna/PosicaoPaciente" exact>
            <PePPa />
          </Route>
          <Route path="/perna/PosProcessamento" exact>
            <PePos />
          </Route>
          <Route path="/perna/PosProcessamentoContraste" exact>
            <PePosCont />
          </Route>
          <Route path="/perna/Programacao" exact>
            <PeProg />
          </Route>
          <Route path="/perna/Reformatacoes" exact>
            <PeRef />
          </Route>
          <Route path="/Quadril/Backup" exact>
            <QuBkp />
          </Route>
          <Route path="/Quadril/BackupContraste" exact>
            <QuBkpCont />
          </Route>
          <Route path="/Quadril/Documentacao" exact>
            <QuDoc />
          </Route>
          <Route path="/Quadril/DocumentacaoContraste" exact>
            <QuDocCont />
          </Route>
          <Route path="/Quadril/ParametrosTecnicos" exact>
            <QuPTe />
          </Route>
          <Route path="/Quadril/PosicaoPaciente" exact>
            <QuPPa />
          </Route>
          <Route path="/Quadril/PosProcessamento" exact>
            <QuPos />
          </Route>
          <Route path="/Quadril/PosProcessamentoContraste" exact>
            <QuPosCont />
          </Route>
          <Route path="/Quadril/Programacao" exact>
            <QuProg />
          </Route>
          <Route path="/Quadril/Reformatacoes" exact>
            <QuRef />
          </Route>
          <Route path="/Tornozelo/Backup" exact>
            <ToBkp />
          </Route>
          <Route path="/Tornozelo/BackupContraste" exact>
            <ToBkpCont />
          </Route>
          <Route path="/Tornozelo/Documentacao" exact>
            <ToDoc />
          </Route>
          <Route path="/Tornozelo/DocumentacaoContraste" exact>
            <ToDocCont />
          </Route>
          <Route path="/Tornozelo/ParametrosTecnicos" exact>
            <ToPTe />
          </Route>
          <Route path="/Tornozelo/PosicaoPaciente" exact>
            <ToPPa />
          </Route>
          <Route path="/Tornozelo/PosProcessamento" exact>
            <ToPos />
          </Route>
          <Route path="/Tornozelo/PosProcessamentoContraste" exact>
            <ToPosCont />
          </Route>
          <Route path="/Tornozelo/Programacao" exact>
            <ToProg />
          </Route>
          <Route path="/Tornozelo/Reformatacoes" exact>
            <ToRef />
          </Route>

          <Route path="/MembrosInferiores/AntePe" exact>
            <AntePe />
          </Route>
          <Route path="/MembrosInferiores/Articulacoes" exact>
            <Articulacoes />
          </Route>
          <Route path="/MembrosInferiores/Bacia" exact>
            <Bacia />
          </Route>
          <Route path="/MembrosInferiores/Coxa" exact>
            <Coxa />
          </Route>
          <Route path="/MembrosInferiores/JoelhoMI" exact>
            <JoelhoMI />
          </Route>
          <Route path="/MembrosInferiores/Perna" exact>
            <Perna />
          </Route>
          <Route path="/MembrosInferiores/Quadril" exact>
            <Quadril />
          </Route>
          <Route path="/MembrosInferiores/Tornozelo" exact>
            <Tornozelo />
          </Route>
          <Route path="/MembrosInferiores" exact>
            <MembrosInferiores />
          </Route>
          <Route path="/AntePe/BackupMenu" exact>
            <APBkpMenu />
          </Route>
          <Route path="/Articulacoes/BackupMenu" exact>
            <ARBkpMenu />
          </Route>
          <Route path="/Bacia/BackupMenu" exact>
            <BaBkpMenu />
          </Route>
          <Route path="/Coxa/BackupMenu" exact>
            <CoBkpMenu />
          </Route>
          <Route path="/JoelhoMI/BackupMenu" exact>
            <JoBkpMenu />
          </Route>
          <Route path="/Perna/BackupMenu" exact>
            <PeBkpMenu />
          </Route>
          <Route path="/Quadril/BackupMenu" exact>
            <QuBkpMenu />
          </Route>
          <Route path="/Tornozelo/BackupMenu" exact>
            <ToBkpMenu />
          </Route>
          <Route path="/AntePe/DocumentacaoMenu" exact >
            <APDocMenu />
          </Route>
          <Route path="/Articulacoes/DocumentacaoMenu" exact >
            <ARDocMenu />
          </Route>
          <Route path="/Bacia/DocumentacaoMenu" exact >
            <BaDocMenu />
          </Route>
          <Route path="/Coxa/DocumentacaoMenu" exact >
            <CoDocMenu />
          </Route>
          <Route path="/JoelhoMI/DocumentacaoMenu" exact >
            <JoDocMenu />
          </Route>
          <Route path="/Perna/DocumentacaoMenu" exact >
            <PeDocMenu />
          </Route>
          <Route path="/Quadril/DocumentacaoMenu" exact >
            <QuDocMenu />
          </Route>
          <Route path="/Tornozelo/DocumentacaoMenu" exact >
            <ToDocMenu />
          </Route>
          <Route path="/AntePe/PosProcessamentoMenu" exact >
            <APPosMenu />
          </Route>
          <Route path="/Articulacoes/PosProcessamentoMenu" exact >
            <ARPosMenu />
          </Route>
          <Route path="/Bacia/PosProcessamentoMenu" exact >
            <BaPosMenu />
          </Route>
          <Route path="/Coxa/PosProcessamentoMenu" exact >
            <CoPosMenu />
          </Route>
          <Route path="/JoelhoMI/PosProcessamentoMenu" exact >
            <JoPosMenu />
          </Route>
          <Route path="/Perna/PosProcessamentoMenu" exact >
            <PePosMenu />
          </Route>
          <Route path="/Quadril/PosProcessamentoMenu" exact >
            <QuPosMenu />
          </Route>
          <Route path="/Tornozelo/PosProcessamentoMenu" exact >
            <ToPosMenu />
          </Route>
          <Route path="/MembrosSuperiores" exact>
            <MembrosSuperiores/>
          </Route>
          <Route path="/MembrosSuperiores/Antebraco" exact>
            <Antebraco/>
          </Route>
          <Route path="/Antebraco/Backup" exact>
            <ABBkp/>
          </Route>
          <Route path="/Antebraco/BackupMenu" exact>
            <ABBkpMenu/>
          </Route>
          <Route path="/Antebraco/BackupContraste" exact>
            <ABBkpCont/>
          </Route>
          <Route path="/Antebraco/Documentacao" exact>
            <ABDoc/>
          </Route>
          <Route path="/Antebraco/DocumentacaoMenu" exact>
            <ABDocMenu/>
          </Route>
          <Route path="/Antebraco/DocumentacaoContraste" exact>
            <ABDocCont/>
          </Route>
          <Route path="/Antebraco/ParametrosTecnicos" exact>
            <ABPTe/>
          </Route>
          <Route path="/Antebraco/PosicaoPaciente" exact>
            <ABPPa/>
          </Route>
          <Route path="/Antebraco/PosProcessamento" exact>
            <ABPos/>
          </Route>
          <Route path="/Antebraco/PosProcessamentoMenu" exact>
            <ABPosMenu/>
          </Route>
          <Route path="/Antebraco/PosProcessamentoContraste" exact>
            <ABPosCont/>
          </Route>
          <Route path="/Antebraco/Programacao" exact>
            <ABProg/>
          </Route>
          <Route path="/Antebraco/Reformatacoes" exact>
            <ABRef/>
          </Route>
          <Route path="/MembrosSuperiores/Braco" exact>
            <Braco/>
          </Route>
          <Route path="/Braco/Backup" exact>
            <BRBkp/>
          </Route>
          <Route path="/Braco/BackupMenu" exact>
            <BRBkpMenu/>
          </Route>
          <Route path="/Braco/BackupContraste" exact>
            <BRBkpCont/>
          </Route>
          <Route path="/Braco/Documentacao" exact>
            <BRDoc/>
          </Route>
          <Route path="/Braco/DocumentacaoMenu" exact>
            <BRDocMenu/>
          </Route>
          <Route path="/Braco/DocumentacaoContraste" exact>
            <BRDocCont/>
          </Route>
          <Route path="/Braco/ParametrosTecnicos" exact>
            <BRPTe/>
          </Route>
          <Route path="/Braco/PosicaoPaciente" exact>
            <BRPPa/>
          </Route>
          <Route path="/Braco/PosProcessamento" exact>
            <BRPos/>
          </Route>
          <Route path="/Braco/PosProcessamentoMenu" exact>
            <BRPosMenu/>
          </Route>
          <Route path="/Braco/PosProcessamentoContraste" exact>
            <BRPosCont/>
          </Route>
          <Route path="/Braco/Programacao" exact>
            <BRProg/>
          </Route>
          <Route path="/Braco/Reformatacoes" exact>
            <BRRef/>
          </Route>
          <Route path="/MembrosSuperiores/Cotovelo" exact>
            <Cotovelo/>
          </Route>
          <Route path="/Cotovelo/Backup" exact>
            <CTBkp/>
          </Route>
          <Route path="/Cotovelo/BackupMenu" exact>
            <CTBkpMenu/>
          </Route>
          <Route path="/Cotovelo/BackupContraste" exact>
            <CTBkpCont/>
          </Route>
          <Route path="/Cotovelo/Documentacao" exact>
            <CTDoc/>
          </Route>
          <Route path="/Cotovelo/DocumentacaoMenu" exact>
            <CTDocMenu/>
          </Route>
          <Route path="/Cotovelo/DocumentacaoContraste" exact>
            <CTDocCont/>
          </Route>
          <Route path="/Cotovelo/ParametrosTecnicos" exact>
            <CTPTe/>
          </Route>
          <Route path="/Cotovelo/PosicaoPaciente" exact>
            <CTPPa/>
          </Route>
          <Route path="/Cotovelo/PosProcessamento" exact>
            <CTPos/>
          </Route>
          <Route path="/Cotovelo/PosProcessamentoMenu" exact>
            <CTPosMenu/>
          </Route>
          <Route path="/Cotovelo/PosProcessamentoContraste" exact>
            <CTPosCont/>
          </Route>
          <Route path="/Cotovelo/Programacao" exact>
            <CTProg/>
          </Route>
          <Route path="/Cotovelo/Reformatacoes" exact>
            <CTRef/>
          </Route>
          <Route path="/MembrosSuperiores/Mao" exact>
            <Mao/>
          </Route>
          <Route path="/Mao/Backup" exact>
            <MABkp/>
          </Route>
          <Route path="/Mao/BackupMenu" exact>
            <MABkpMenu/>
          </Route>
          <Route path="/Mao/BackupContraste" exact>
            <MABkpCont/>
          </Route>
          <Route path="/Mao/Documentacao" exact>
            <MADoc/>
          </Route>
          <Route path="/Mao/DocumentacaoMenu" exact>
            <MADocMenu/>
          </Route>
          <Route path="/Mao/DocumentacaoContraste" exact>
            <MADocCont/>
          </Route>
          <Route path="/Mao/ParametrosTecnicos" exact>
            <MAPTe/>
          </Route>
          <Route path="/Mao/PosicaoPaciente" exact>
            <MAPPa/>
          </Route>
          <Route path="/Mao/PosProcessamento" exact>
            <MAPos/>
          </Route>
          <Route path="/Mao/PosProcessamentoMenu" exact>
            <MAPosMenu/>
          </Route>
          <Route path="/Mao/PosProcessamentoContraste" exact>
            <MAPosCont/>
          </Route>
          <Route path="/Mao/Programacao" exact>
            <MAProg/>
          </Route>
          <Route path="/Mao/Reformatacoes" exact>
            <MARef/>
          </Route>
          <Route path="/MembrosSuperiores/Ombro" exact>
            <Ombro/>
          </Route>
          <Route path="/Ombro/Backup" exact>
            <OBBkp/>
          </Route>
          <Route path="/Ombro/BackupMenu" exact>
            <OBBkpMenu/>
          </Route>
          <Route path="/Ombro/BackupContraste" exact>
            <OBBkpCont/>
          </Route>
          <Route path="/Ombro/Documentacao" exact>
            <OBDoc/>
          </Route>
          <Route path="/Ombro/DocumentacaoMenu" exact>
            <OBDocMenu/>
          </Route>
          <Route path="/Ombro/DocumentacaoContraste" exact>
            <OBDocCont/>
          </Route>
          <Route path="/Ombro/ParametrosTecnicos" exact>
            <OBPTe/>
          </Route>
          <Route path="/Ombro/PosicaoPaciente" exact>
            <OBPPa/>
          </Route>
          <Route path="/Ombro/PosProcessamento" exact>
            <OBPos/>
          </Route>
          <Route path="/Ombro/PosProcessamentoMenu" exact>
            <OBPosMenu/>
          </Route>
          <Route path="/Ombro/PosProcessamentoContraste" exact>
            <OBPosCont/>
          </Route>
          <Route path="/Ombro/Programacao" exact>
            <OBProg/>
          </Route>
          <Route path="/Ombro/Reformatacoes" exact>
            <OBRef/>
          </Route>
          <Route path="/MembrosSuperiores/Punho" exact>
            <Punho/>
          </Route>
          <Route path="/Punho/Backup" exact>
            <PUBkp/>
          </Route>
          <Route path="/Punho/BackupMenu" exact>
            <PUBkpMenu/>
          </Route>
          <Route path="/Punho/BackupContraste" exact>
            <PUBkpCont/>
          </Route>
          <Route path="/Punho/Documentacao" exact>
            <PUDoc/>
          </Route>
          <Route path="/Punho/DocumentacaoMenu" exact>
            <PUDocMenu/>
          </Route>
          <Route path="/Punho/DocumentacaoContraste" exact>
            <PUDocCont/>
          </Route>
          <Route path="/Punho/ParametrosTecnicos" exact>
            <PUPTe/>
          </Route>
          <Route path="/Punho/PosicaoPaciente" exact>
            <PUPPa/>
          </Route>
          <Route path="/Punho/PosProcessamento" exact>
            <PUPos/>
          </Route>
          <Route path="/Punho/PosProcessamentoMenu" exact>
            <PUPosMenu/>
          </Route>
          <Route path="/Punho/PosProcessamentoContraste" exact>
            <PUPosCont/>
          </Route>
          <Route path="/Punho/Programacao" exact>
            <PUProg/>
          </Route>
          <Route path="/Punho/Reformatacoes" exact>
            <PURef/>
          </Route>

          <Route path="/Coluna" exact>
            <Coluna />
          </Route>
          <Route path="/ColunaCervical" exact>
            <ColunaCervical />
          </Route>
          <Route path="/ColunaCervical/Backup" exact>
            <CCBkp />
          </Route>
          <Route path="/ColunaCervical/BackupMenu" exact>
            <CCBkpMenu />
          </Route>
          <Route path="/ColunaCervical/BackupContraste" exact>
            <CCBkpCont />
          </Route>
          <Route path="/ColunaCervical/BackupTrauma" exact>
            <CCBkpTrau />
          </Route>
          <Route path="/ColunaCervical/BackupDisco" exact>
            <CCBkpDisc />
          </Route>
          <Route path="/ColunaCervical/Documentacao" exact>
            <CCDoc />
          </Route>
          <Route path="/ColunaCervical/DocumentacaoMenu" exact>
            <CCDocMenu />
          </Route>
          <Route path="/ColunaCervical/DocumentacaoContraste" exact>
            <CCDocCont />
          </Route>
          <Route path="/ColunaCervical/DocumentacaoDisco" exact>
            <CCDocDisc />
          </Route>
          <Route path="/ColunaCervical/DocumentacaoTrauma" exact>
            <CCDocTrau />
          </Route>
          <Route path="/ColunaCervical/ParametrosTecnicos" exact>
            <CCPTe />
          </Route>
          <Route path="/ColunaCervical/PosicaoPaciente" exact>
            <CCPPa />
          </Route>
          <Route path="/ColunaCervical/PosProcessamento" exact>
            <CCPos />
          </Route>
          <Route path="/ColunaCervical/PosProcessamentoMenu" exact>
            <CCPosMenu />
          </Route>
          <Route path="/ColunaCervical/PosProcessamentoContraste" exact>
            <CCPosCont />
          </Route>
          <Route path="/ColunaCervical/Programacao" exact>
            <CCProg />
          </Route>
          <Route path="/ColunaCervical/Reformatacoes" exact>
            <CCRef />
          </Route>
          <Route path="/ColunaLombar" exact>
            <ColunaLombar />
          </Route>
          <Route path="/ColunaLombar/Backup" exact>
            <CLBkp />
          </Route>
          <Route path="/ColunaLombar/BackupMenu" exact>
            <CLBkpMenu />
          </Route>
          <Route path="/ColunaLombar/BackupContraste" exact>
            <CLBkpCont />
          </Route>
          <Route path="/ColunaLombar/BackupTrauma" exact>
            <CLBkpTrau />
          </Route>
          <Route path="/ColunaLombar/BackupDisco" exact>
            <CLBkpDisc />
          </Route>
          <Route path="/ColunaLombar/Documentacao" exact>
            <CLDoc />
          </Route>
          <Route path="/ColunaLombar/DocumentacaoMenu" exact>
            <CLDocMenu />
          </Route>
          <Route path="/ColunaLombar/DocumentacaoContraste" exact>
            <CLDocCont />
          </Route>
          <Route path="/ColunaLombar/DocumentacaoDisco" exact>
            <CLDocDisc />
          </Route>
          <Route path="/ColunaLombar/DocumentacaoTrauma" exact>
            <CLDocTrau />
          </Route>
          <Route path="/ColunaLombar/ParametrosTecnicos" exact>
            <CLPTe />
          </Route>
          <Route path="/ColunaLombar/PosicaoPaciente" exact>
            <CLPPa />
          </Route>
          <Route path="/ColunaLombar/PosProcessamento" exact>
            <CLPos />
          </Route>
          <Route path="/ColunaLombar/PosProcessamentoMenu" exact>
            <CLPosMenu />
          </Route>
          <Route path="/ColunaLombar/PosProcessamentoContraste" exact>
            <CLPosCont />
          </Route>
          <Route path="/ColunaLombar/Programacao" exact>
            <CLProg />
          </Route>
          <Route path="/ColunaLombar/Reformatacoes" exact>
            <CLRef />
          </Route>
          <Route path="/ColunaToracica" exact>
            <ColunaToracica />
          </Route>
          <Route path="/ColunaToracica/Backup" exact>
            <CRBkp />
          </Route>
          <Route path="/ColunaToracica/BackupMenu" exact>
            <CRBkpMenu />
          </Route>
          <Route path="/ColunaToracica/BackupContraste" exact>
            <CRBkpCont />
          </Route>
          <Route path="/ColunaToracica/BackupTrauma" exact>
            <CRBkpTrau />
          </Route>
          <Route path="/ColunaToracica/BackupDisco" exact>
            <CRBkpDisc />
          </Route>
          <Route path="/ColunaToracica/Documentacao" exact>
            <CRDoc />
          </Route>
          <Route path="/ColunaToracica/DocumentacaoMenu" exact>
            <CRDocMenu />
          </Route>
          <Route path="/ColunaToracica/DocumentacaoContraste" exact>
            <CRDocCont />
          </Route>
          <Route path="/ColunaToracica/DocumentacaoDisco" exact>
            <CRDocDisc />
          </Route>
          <Route path="/ColunaToracica/DocumentacaoTrauma" exact>
            <CRDocTrau />
          </Route>
          <Route path="/ColunaToracica/ParametrosTecnicos" exact>
            <CRPTe />
          </Route>
          <Route path="/ColunaToracica/PosicaoPaciente" exact>
            <CRPPa />
          </Route>
          <Route path="/ColunaToracica/PosProcessamento" exact>
            <CRPos />
          </Route>
          <Route path="/ColunaToracica/PosProcessamentoMenu" exact>
            <CRPosMenu />
          </Route>
          <Route path="/ColunaToracica/PosProcessamentoContraste" exact>
            <CRPosCont />
          </Route>
          <Route path="/ColunaToracica/Programacao" exact>
            <CRProg />
          </Route>
          <Route path="/ColunaToracica/Reformatacoes" exact>
            <CRRef />
          </Route>

          <Route path="/ColunaToracica/PosProcessamentoTrauma" exact>
            <CRPosTrau/>
          </Route>
          <Route path="/ColunaToracica/PosProcessamentoDisco" exact>
            <CRPosDisc/>
          </Route>
          <Route path="/ColunaLombar/PosProcessamentoTrauma" exact>
            <CLPosTrau/>
          </Route>
          <Route path="/ColunaLombar/PosProcessamentoDisco" exact>
            <CLPosDisc/>
          </Route>
          <Route path="/ColunaCervical/PosProcessamentoTrauma" exact>
            <CCPosTrau/>
          </Route>
          <Route path="/ColunaCervical/PosProcessamentoDisco" exact>
            <CCPosDisc/>
          </Route>

          <Route path="/Cabeca" exact>
            <Cabeca/>
          </Route>
          <Route path="/Atm" exact>
            <Atm/>
          </Route>
          <Route path="/Atm/Backup" exact>
            <AtBkp/>
          </Route>
          <Route path="/Atm/BackupMenu" exact>
            <AtBkpMenu/>
          </Route>
          <Route path="/Atm/BackupContraste" exact>
            <AtBkpCont/>
          </Route>
          <Route path="/Atm/Documentacao" exact>
            <AtDoc/>
          </Route>
          <Route path="/Atm/DocumentacaoMenu" exact>
            <AtDocMenu/>
          </Route>
          <Route path="/Atm/DocumentacaoContraste" exact>
            <AtDocCont/>
          </Route>
          <Route path="/Atm/ParametrosTecnicos" exact>
            <AtPTe/>
          </Route>
          <Route path="/Atm/PosicaoPaciente" exact>
            <AtPPa/>
          </Route>
          <Route path="/Atm/PosProcessamento" exact>
            <AtPos/>
          </Route>
          <Route path="/Atm/PosProcessamentoMenu" exact>
            <AtPosMenu/>
          </Route>
          <Route path="/Atm/PosProcessamentoContraste" exact>
            <AtPosCont/>
          </Route>
          <Route path="/Atm/Programacao" exact>
            <AtProg/>
          </Route>
          <Route path="/Atm/Reformatacoes" exact>
            <AtRef/>
          </Route>
          <Route path="/Cranio" exact>
            <Cranio/>
          </Route>
          <Route path="/Cranio/Backup" exact>
            <CNBkp/>
          </Route>
          <Route path="/Cranio/BackupMenu" exact>
            <CNBkpMenu/>
          </Route>
          <Route path="/Cranio/BackupContraste" exact>
            <CNBkpCont/>
          </Route>
          <Route path="/Cranio/Documentacao" exact>
            <CNDoc/>
          </Route>
          <Route path="/Cranio/DocumentacaoMenu" exact>
            <CNDocMenu/>
          </Route>
          <Route path="/Cranio/DocumentacaoContraste" exact>
            <CNDocCont/>
          </Route>
          <Route path="/Cranio/ParametrosTecnicos" exact>
            <CNPTe/>
          </Route>
          <Route path="/Cranio/PosicaoPaciente" exact>
            <CNPPa/>
          </Route>
          <Route path="/Cranio/PosProcessamento" exact>
            <CNPos/>
          </Route>
          <Route path="/Cranio/PosProcessamentoMenu" exact>
            <CNPosMenu/>
          </Route>
          <Route path="/Cranio/PosProcessamentoContraste" exact>
            <CNPosCont/>
          </Route>
          <Route path="/Cranio/Programacao" exact>
            <CNProg/>
          </Route>
          <Route path="/Cranio/Reformatacoes" exact>
            <CNRef/>
          </Route>
          <Route path="/Face" exact>
            <Face/>
          </Route>
          <Route path="/Face/Backup" exact>
            <FCBkp/>
          </Route>
          <Route path="/Face/BackupMenu" exact>
            <FCBkpMenu/>
          </Route>
          <Route path="/Face/BackupContraste" exact>
            <FCBkpCont/>
          </Route>
          <Route path="/Face/Documentacao" exact>
            <FCDoc/>
          </Route>
          <Route path="/Face/DocumentacaoMenu" exact>
            <FCDocMenu/>
          </Route>
          <Route path="/Face/DocumentacaoContraste" exact>
            <FCDocCont/>
          </Route>
          <Route path="/Face/ParametrosTecnicos" exact>
            <FCPTe/>
          </Route>
          <Route path="/Face/PosicaoPaciente" exact>
            <FCPPa/>
          </Route>
          <Route path="/Face/PosProcessamento" exact>
            <FCPos/>
          </Route>
          <Route path="/Face/PosProcessamentoMenu" exact>
            <FCPosMenu/>
          </Route>
          <Route path="/Face/PosProcessamentoContraste" exact>
            <FCPosCont/>
          </Route>
          <Route path="/Face/Programacao" exact>
            <FCProg/>
          </Route>
          <Route path="/Face/Reformatacoes" exact>
            <FCRef/>
          </Route>
          <Route path="/Hipofise" exact>
            <Hipofise/>
          </Route>
          <Route path="/Hipofise/Backup" exact>
            <HFBkp/>
          </Route>
          <Route path="/Hipofise/BackupMenu" exact>
            <HFBkpMenu/>
          </Route>
          <Route path="/Hipofise/BackupContraste" exact>
            <HFBkpCont/>
          </Route>
          <Route path="/Hipofise/Documentacao" exact>
            <HFDoc/>
          </Route>
          <Route path="/Hipofise/DocumentacaoMenu" exact>
            <HFDocMenu/>
          </Route>
          <Route path="/Hipofise/DocumentacaoContraste" exact>
            <HFDocCont/>
          </Route>
          <Route path="/Hipofise/ParametrosTecnicos" exact>
            <HFPTe/>
          </Route>
          <Route path="/Hipofise/PosicaoPaciente" exact>
            <HFPPa/>
          </Route>
          <Route path="/Hipofise/PosProcessamento" exact>
            <HFPos/>
          </Route>
          <Route path="/Hipofise/PosProcessamentoMenu" exact>
            <HFPosMenu/>
          </Route>
          <Route path="/Hipofise/PosProcessamentoContraste" exact>
            <HFPosCont/>
          </Route>
          <Route path="/Hipofise/Programacao" exact>
            <HFProg/>
          </Route>
          <Route path="/Hipofise/Reformatacoes" exact>
            <HFRef/>
          </Route>
          <Route path="/Orbitas" exact>
            <Orbitas/>
          </Route>
          <Route path="/Orbitas/Backup" exact>
            <ORBkp/>
          </Route>
          <Route path="/Orbitas/BackupMenu" exact>
            <ORBkpMenu/>
          </Route>
          <Route path="/Orbitas/BackupContraste" exact>
            <ORBkpCont/>
          </Route>
          <Route path="/Orbitas/Documentacao" exact>
            <ORDoc/>
          </Route>
          <Route path="/Orbitas/DocumentacaoMenu" exact>
            <ORDocMenu/>
          </Route>
          <Route path="/Orbitas/DocumentacaoContraste" exact>
            <ORDocCont/>
          </Route>
          <Route path="/Orbitas/ParametrosTecnicos" exact>
            <ORPTe/>
          </Route>
          <Route path="/Orbitas/PosicaoPaciente" exact>
            <ORPPa/>
          </Route>
          <Route path="/Orbitas/PosProcessamento" exact>
            <ORPos/>
          </Route>
          <Route path="/Orbitas/PosProcessamentoMenu" exact>
            <ORPosMenu/>
          </Route>
          <Route path="/Orbitas/PosProcessamentoContraste" exact>
            <ORPosCont/>
          </Route>
          <Route path="/Orbitas/Programacao" exact>
            <ORProg/>
          </Route>
          <Route path="/Orbitas/Reformatacoes" exact>
            <ORRef/>
          </Route>
          <Route path="/Ouvidos" exact>
            <Ouvidos/>
          </Route>
          <Route path="/Ouvidos/Backup" exact>
            <OUBkp/>
          </Route>
          <Route path="/Ouvidos/BackupMenu" exact>
            <OUBkpMenu/>
          </Route>
          <Route path="/Ouvidos/BackupContraste" exact>
            <OUBkpCont/>
          </Route>
          <Route path="/Ouvidos/Documentacao" exact>
            <OUDoc/>
          </Route>
          <Route path="/Ouvidos/DocumentacaoMenu" exact>
            <OUDocMenu/>
          </Route>
          <Route path="/Ouvidos/DocumentacaoContraste" exact>
            <OUDocCont/>
          </Route>
          <Route path="/Ouvidos/ParametrosTecnicos" exact>
            <OUPTe/>
          </Route>
          <Route path="/Ouvidos/PosicaoPaciente" exact>
            <OUPPa/>
          </Route>
          <Route path="/Ouvidos/PosProcessamento" exact>
            <OUPos/>
          </Route>
          <Route path="/Ouvidos/PosProcessamentoMenu" exact>
            <OUPosMenu/>
          </Route>
          <Route path="/Ouvidos/PosProcessamentoContraste" exact>
            <OUPosCont/>
          </Route>
          <Route path="/Ouvidos/Programacao" exact>
            <OUProg/>
          </Route>
          <Route path="/Ouvidos/Reformatacoes" exact>
            <OURef/>
          </Route>
          <Route path="/Pescoco" exact>
            <Pescoco/>
          </Route>
          <Route path="/Pescoco/Backup" exact>
            <PEBkp/>
          </Route>
          <Route path="/Pescoco/BackupMenu" exact>
            <PEBkpMenu/>
          </Route>
          <Route path="/Pescoco/BackupContraste" exact>
            <PEBkpCont/>
          </Route>
          <Route path="/Pescoco/Documentacao" exact>
            <PEDoc/>
          </Route>
          <Route path="/Pescoco/DocumentacaoMenu" exact>
            <PEDocMenu/>
          </Route>
          <Route path="/Pescoco/DocumentacaoContraste" exact>
            <PEDocCont/>
          </Route>
          <Route path="/Pescoco/ParametrosTecnicos" exact>
            <PEPTe/>
          </Route>
          <Route path="/Pescoco/PosicaoPaciente" exact>
            <PEPPa/>
          </Route>
          <Route path="/Pescoco/PosProcessamento" exact>
            <PEPos/>
          </Route>
          <Route path="/Pescoco/PosProcessamentoMenu" exact>
            <PEPosMenu/>
          </Route>
          <Route path="/Pescoco/PosProcessamentoContraste" exact>
            <PEPosCont/>
          </Route>
          <Route path="/Pescoco/Programacao" exact>
            <PEProg/>
          </Route>
          <Route path="/Pescoco/Reformatacoes" exact>
            <PERef/>
          </Route>
          <Route path="/SeiosFace" exact>
            <SeiosFace/>
          </Route>
          <Route path="/SeiosFace/Backup" exact>
            <SFBkp/>
          </Route>
          <Route path="/SeiosFace/BackupMenu" exact>
            <SFBkpMenu/>
          </Route>
          <Route path="/SeiosFace/BackupContraste" exact>
            <SFBkpCont/>
          </Route>
          <Route path="/SeiosFace/Documentacao" exact>
            <SFDoc/>
          </Route>
          <Route path="/SeiosFace/DocumentacaoMenu" exact>
            <SFDocMenu/>
          </Route>
          <Route path="/SeiosFace/DocumentacaoContraste" exact>
            <SFDocCont/>
          </Route>
          <Route path="/SeiosFace/ParametrosTecnicos" exact>
            <SFPTe/>
          </Route>
          <Route path="/SeiosFace/PosicaoPaciente" exact>
            <SFPPa/>
          </Route>
          <Route path="/SeiosFace/PosProcessamento" exact>
            <SFPos/>
          </Route>
          <Route path="/SeiosFace/PosProcessamentoMenu" exact>
            <SFPosMenu/>
          </Route>
          <Route path="/SeiosFace/PosProcessamentoContraste" exact>
            <SFPosCont/>
          </Route>
          <Route path="/SeiosFace/Programacao" exact>
            <SFProg/>
          </Route>
          <Route path="/SeiosFace/Reformatacoes" exact>
            <SFRef/>
          </Route>
        </Switch>
        <BackButton/>
      </Router>

    </div>
  )
}
