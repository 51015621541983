import { 
    Container,
    Author
 } from "./styles";

import mpcas from '../../assets/MPCAS.png'
import { useHistory } from "react-router-dom";

export default function Home(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Author>Roger Passos Pereira</Author>
                    <Author>Geraldo Magela Salomé </Author>
                    <Author>José Dias da Silva Neto </Author>
                    <Author>Augusto Castelli Von Atzingen</Author>
                </div>
            </div>

            <div className="row">
                <div className="col text-center">
                    <img className="img-fluid" src={mpcas} alt="Mestrao Profissional em Ciências Aplicadas à Saúde" />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col text-center">
                    <button 
                        className="btn btn-info"
                        onClick={() => history.push("/sumario")}
                    >Iniciar</button>
                </div>
            </div>
        </Container>
    )
}