import { Title } from "../../../components";
import { Container } from "./styles";

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Programação</Title>

                    <section className="mt-2 p-3">

                        <p>
                            <b>Scout: </b>Scout AP (0 grau)<br/>
                            <b>m.A: </b>10<br/>
                            <b>Início: </b>S100<br />
                            <b>Fim: </b>I350<br />
                        </p>

                        <p>
                            <b>Scout: </b>Scout Perfil (90 graus)<br/>
                            <b>m.A: </b>30<br/>
                            <b>Início: </b>S100<br />
                            <b>Fim: </b>I350<br />
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}