import {
    Container
} from './styles'

import {
    Title
} from '../../components'
import { useHistory } from 'react-router-dom'

export default function MembrosInferiores(){

    const items = [
        {
            text: "Tomografia Computadorizada Multislice das Articulações Sacroilíacas",
            url: "/articulacoes"
        },
        {
            text: "Tomografia Computadorizada Multislice da Bacia",
            url: "/bacia"
        },
        {
            text: "Tomografia Computadorizada Multislice do Quadril",
            url: "/quadril"
        },
        {
            text: "Tomografia Computadorizada Multislice da Coxa",
            url: "/coxa"
        },
        {
            text: "Tomografia Computadorizada Multislice do Joelho",
            url: "/joelhoMI"
        },
        {
            text: "Tomografia Computadorizada Multislice da Perna",
            url: "/perna"
        },
        {
            text: "Tomografia Computadorizada Multislice do Tornozelo",
            url: "/tornozelo"
        },
        {
            text: "Tomografia Computadorizada Multislice do Ante Pé",
            url: "/antePe"
        },
    ]

    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Membros Inferiores</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`/MembrosInferiores${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}