import {
    Container
} from './styles'

import {
    Title
} from '../../../components'
import { useHistory } from 'react-router-dom'

export default function AntePe(){

    const items = [
        {
            text: "Parâmetros Técnicos",
            url: "/parametrosTecnicos"
        },
        {
            text: "Posição do paciente",
            url: "/posicaoPaciente"
        },
        {
            text: "Programação",
            url: "/programacao"
        },
        {
            text: "Reformatações multiplanares",
            url: "/reformatacoes"
        },
        {
            text: "Protocolos de Pós-Processamento",
            url: "/posProcessamentoMenu"
        },
        {
            text: "Back-Up",
            url: "/backupMenu"
        },
        {
            text: "Documentação",
            url: "/documentacaoMenu"
        }
    ]

    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Tomografia Computadorizada multislice do Ante pé</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`/antePe${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}