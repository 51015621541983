import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Orientações Gerais</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <p>
                        <b>Parâmetros técnicos:</b> Segue como sugestão de parâmetros utilizados em um
                    equipamento de 16 canais.
                    </p>
                    
                    <p>
                        <b>Posição do paciente:</b> Segue como sugestão de posicionamento devendo ser
                    adequado a rotina clínica do serviço ou a particularidade de cada paciente.
                    </p>

                    <p>
                        <b>Programação:</b> Segue como sugestões para programação, nomeação das séries e
                    filtros utilizados.
                    </p>
                    
                    <p>
                        <b>Reformatações multiplanares:</b> Segue como sugestão de referências anatomicas
                    com orientações de início e fim para realização das reformatações multiplanares
                    e reconstruções tridimencionais.
                    </p>
                    
                    <p>
                        <b>Protocolos de pós-processamento:</b> Segue como sugestões de protocolos
                    direcionados a cada região anatômica podendo ser utilizados como protocolos
                    iniciais e conforme a nescessidade adcionadas ou removidas sequências
                    específicas para a patologia a ser estudada.
                    </p>
                    
                    <p>
                        <b>Back-up:</b> Segue como sugestões para back-up podendo ser utilizados como
                    protocolos iniciais e conforme a nescessidade adcionadas ou removidas
                    sequências específicas de acordo com a capacidade de envio e armazenamento
                    do serviço.
                    </p>
                    
                    <p>
                        <b>Documentação:</b> A documentação de imagens tem acompanhando a evolução
                    tecnológica, pode ser utilizado filme radiográfico, papel, CD/DVD, pendrive,
                    disponibilização em rede interna (PACS) ou mesmo via internet. Deve ser
                    adaptada de forma a atender as nescessidades do serviço e paciente.
                    </p>
                </div>
            </div>

        </Container>
    )
}