import {
    Container
} from './styles'

export default function Title({ children }){
    return (
        <Container className="m-2 p-2">
            {children}
        </Container>
    )
}