import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/articulacoes/img2.png'
import img3 from '../../../assets/articulacoes/img3.png'
import img4 from '../../../assets/articulacoes/img4.png'
import img5 from '../../../assets/articulacoes/img5.png'
import img6 from '../../../assets/articulacoes/img6.png'
import img7 from '../../../assets/articulacoes/img7.png'
import img8 from '../../../assets/articulacoes/img8.png'
import img9 from '../../../assets/articulacoes/img9.png'
import img10 from '../../../assets/articulacoes/img10.png'
import img11 from '../../../assets/articulacoes/img11.png'
import img12 from '../../../assets/articulacoes/img12.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano sagital, deixar os cortes perpendiculares ao sacro. Caso o sacro
esteja girado, corrigir no plano coronal.
                        </p>

                        <p>
                        Para back-up os cortes axiais P.M. devem ter início logo no começo de L5 e término 3 cm
abaixo das articulações sacroilíacas.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes axiais Osso devem ter início logo no começo de L5 e término 3 cm
abaixo das articulações sacroilíacas.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes axiais P.M. devem ter início logo no final de L5 e término logo após o final
das articulações sacroilíacas.
                        </p>
                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes axiais Osso devem ter início logo no final de L5 e término logo após o final das
articulações sacroilíacas.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar o sacro como referência para angulação, deixar os cortes paralelos a ele, utilizar
imagem no plano sagital como referência. No plano axial deixar os cortes paralelos ao maior
eixo do sacro, utilizar imagem axial como referência.
                        </p>

                        <p>
                        Para back-up os cortes coronais P.M. devem ter início 3 cm posterior as articulações
sacroilíacas e término 3 cm anterior as articulações sacroilíacas.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes coronais Osso devem ter início 3 cm posterior as articulações
sacroilíacas e término 3 cm anterior as articulações sacroilíacas.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes coronais P.M. devem ter início 0.5 cm posterior as articulações sacroilíacas
e término 0.5 cm anterior as articulações sacroilíacas.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para filme os cortes coronais Osso devem ter início 0.5 cm posterior as articulações
sacroilíacas e término 0.5 cm anterior as articulações sacroilíacas.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar o sacro como referência para angulação, na imagem no plano axial deixar os cortes
perpendiculares ao maior eixo do sacro. No plano coronal deixar os cortes paralelos ao maior eixo
do sacro, utilizar imagem coronal como referência.
                        </p>

                        <p>
                        Para back-up os cortes sagitais P.M. das articulações sacroilíacas devem ter início 3 cm antes da
articulação sacroilíaca esquerda e término 3 cm após a articulação sacroilíaca direita.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para back-up os cortes sagitais Osso das articulações sacroilíacas devem ter início 3 cm antes
da articulação sacroilíaca esquerda e término 3 cm após a articulação sacroilíaca direita.
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo das
articulações sacroilíacas de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />



                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea, desgaste e fraturas.</li>
                            <li>3D VR MIP Ósseo é útil em demonstrar anatomia óssea, desgaste e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}