import { Container } from "./styles";

import tcmd from '../../assets/tcmd_white.png'

export default function Header(){
    return (
        <Container className="p-2 mb-3 text-center">
            <img className="img-fluid" src={tcmd} alt="Pós-processamento de imagens em TCMD" />
        </Container>
    )
}