import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Limites para aquisição das imagens com contraste I.V.:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            A programação da fase arterial, portal e de equilíbrio são idênticas a fase sem contraste I.V.
                        </p>
                        
                    </section>
                </div>
            </div>
        </Container>
    )
}