import { Title } from '../../../components'
import {
    Container
} from './styles'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Back-Up</Title>

                    <section className="mt-2 p-3">

                        <ul>
                            <li>Scout;</li>
                            <li>Axial Osso;</li>
                            <li>Axial P.M. S/C;</li>
                            <li>Axial P.M. C/C;</li>
                            <li>Coronal Osso;</li>
                            <li>Coronal P.M. S/C;</li>
                            <li>Coronal P.M. C/C;</li>
                            <li>Sagital Osso;</li>
                            <li>Sagital P.M. S/C;</li>
                            <li>Sagital P.M. C/C;</li>
                            <li>3D VR Joelho Dir. / Esq.;</li>
                            <li>3D VR Joelho Sup. / Inf.;</li>
                            <li>3D MIP Joelho Dir. / Esq.;</li>
                            <li>3D MIP Joelho Sup. / Inf.;</li>
                            <li>Relatório de Dose</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}