import {
    Container
} from './styles'

import {
    Title
} from '../../components'
import { useHistory } from 'react-router-dom'

export default function MembrosSuperiores(){

    const items = [
        {
            text: "Tomografia Computadorizada Multislice do Ombro",
            url: "/ombro"
        },
        {
            text: "Tomografia Computadorizada Multislice do Braço",
            url: "/braco"
        },
        {
            text: "Tomografia Computadorizada Multislice do Cotovelo",
            url: "/cotovelo"
        },
        {
            text: "Tomografia Computadorizada Multislice do Antebraço",
            url: "/antebraco"
        },
        {
            text: "Tomografia Computadorizada Multislice do Punho",
            url: "/punho"
        },
        {
            text: "Tomografia Computadorizada Multislice da Mão",
            url: "/mao"
        },
    ]

    const history = useHistory()
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Membros Superiores</Title>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10">
                    {
                        items.map(item => (
                            <button
                                key={item.url}
                                className="btn btn-info btn-block my-4"
                                onClick={
                                    () => history.push(`/MembrosSuperiores${ item.url }`)
                                }
                            >{ item.text }</button>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}