import { useHistory, useLocation } from 'react-router-dom'

export default function BackButton () {
    const history = useHistory()
    const location = useLocation()

    if(location.pathname !== "/"){
        return (
            <div className="text-center m-3">
                <button 
                    onClick={() => {
                        history.goBack()
                    }}
                    className="btn btn-warning"
                >Voltar</button>
            </div>
        )
    }else{
        return null
    }
  }