import { Title } from "../../../components";
import { Container } from "./styles";



export default function AquisicaoImagens(){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Aquisição de imagens para Tórax</Title>

                    <section className="mt-2 p-3">
                        <h6 className="text-center font-weight-bold">Limites para aquisição das imagens:</h6>

                        <p>
                            Os cortes devem ser programados seguindo a orientação da inferior para a superior, deixar como margem de segurança 4cm acima do ápice pulmonar e 4cm abaixo do seio costofrênico conforme ilustração abaixo.
                        </p>
                    </section>
                </div>
            </div>
        </Container>
    )
}