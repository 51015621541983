import { 
    Container,
    Author
 } from "./styles";
import { useHistory } from "react-router-dom";
import { BackButton, Title } from '../../components'

export default function Sumario(){
    const history = useHistory()
    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    <Title>Desenvolvedor</Title>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col text-center">
                    <p>
                        Marcos Antônio dos Santos<br/>
                        Contato: marcosantonio.dev@gmail.com
                    </p>
                </div>
            </div>
        </Container>
    )
}