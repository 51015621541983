import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/seiosface/img2.PNG'
import img3 from '../../../assets/seiosface/img3.PNG'
import img4 from '../../../assets/seiosface/img4.PNG'
import img5 from '../../../assets/seiosface/img5.PNG'
import img6 from '../../../assets/seiosface/img6.PNG'
import img7 from '../../../assets/seiosface/img7.PNG'
import img8 from '../../../assets/seiosface/img8.PNG'
import img9 from '../../../assets/seiosface/img9.PNG'
import img10 from '../../../assets/seiosface/img10.PNG'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                            Na reformatação no plano axial os cortes devem seguir a orientação da inferior para superior, utilizar o palato ósseo como referência para angulação, deixar os cortes paralelos ao palato ósseo, selecionar imagem no plano sagital como referência. Caso as imagens estejam giradas, corrigir no plano coronal.<br/>
                            Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo abaixo dos seios maxilares e término no final do seio frontal.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo abaixo dos seios maxilares e término no final do seio frontal.
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para anterior, utilizar o palato ósseo como referência para angulação, deixar os cortes perpendiculares a ele, utilizar imagem no plano sagital como referência.  Caso a imagem esteja girada corrigir no plano axial.<br/>
                            Para back-up os cortes coronais P.M. (sem e com contraste I.V.) devem ter início antes do seio esfenoidal e término logo após o ápice do nariz.  
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes coronais Osso devem ter início antes do seio esfenoidal e término logo após o ápice do nariz.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início antes do seio esfenoidal e término logo após o seio frontal.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                            Para filme, os cortes coronais Osso filme devem ter início antes do seio esfenoidal e término logo após o seio frontal.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                            Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita, utilizar septo nasal como referência para angulação, utilizar imagem no plano axial como referência. Caso a imagem esteja girada corrigir no plano coronal.<br/>
                            Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) e sagital P.M. filme devem ter início antes do seio maxilar esquerdo e término logo após o final do seio maxilar direito.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                            Para back-up os cortes sagitais Osso e sagital Osso filme devem ter início antes do seio maxilar esquerdo e término logo após o final do seio maxilar direito.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p className="text-center">
                            Abaixo algumas opções de reformatações tridimensionais para complementar o estudo dos seios da face de acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <ul>
                            <li>3D VR Partes Moles tem valor em demonstrar deformidade, assimetria e patologias nas partes moles.</li>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea, desvio de sépto e fraturas. </li>
                            <li>3D estruturas aeradas mostra o interior das cavidades dos seios da face preenchidas com ar.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}