import { Title } from '../../../components'
import {
    Container
} from './styles'

import img2 from '../../../assets/bacia/img2.png'
import img3 from '../../../assets/bacia/img3.png'
import img4 from '../../../assets/bacia/img4.png'
import img5 from '../../../assets/bacia/img5.png'
import img6 from '../../../assets/bacia/img6.png'
import img7 from '../../../assets/bacia/img7.png'
import img8 from '../../../assets/bacia/img8.png'
import img9 from '../../../assets/bacia/img9.png'
import img10 from '../../../assets/bacia/img10.png'
import img11 from '../../../assets/bacia/img11.png'
import img12 from '../../../assets/bacia/img12.png'

export default function (){
    return (
        <Container>
            <div className="row">
                <div className="col">
                    <Title>Reformatações multiplanares, tridimensionais e imagens de referência:</Title>

                    <section className="mt-2 p-3">

                        <p>
                        Na reformatação no plano axial os cortes devem seguir a orientação da superior para inferior,
selecionar imagem no plano coronal como referência. Deixar os cortes paralelos as cristas
ilíacas, utilizar o plano coronal.
                        </p>

                        <p>
                        Para filme, os cortes axiais P.M. (sem e com contraste I.V.) devem ter início logo acima das
cristas ilíacas e término 1 cm abaixo do trocanter menor.
                        </p>

                        <img className="img-fluid" src={img2} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes axiais Osso devem ter início logo acima das cristas ilíacas e término 1 cm
abaixo do trocanter menor
                        </p>

                        <img className="img-fluid" src={img3} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano coronal os cortes devem seguir a orientação da posterior para
anterior, utilizar as cabeças femorais como referência para angulação, deixar os cortes paralelos
a elas, utilizar imagem no plano axial como referência. No plano sagital deixar os cortes
paralelos ao maior eixo da pelve, utilizar imagem sagital como referência.
                        </p>

                        <p>
                        Para back-up, os cortes coronais P.M. (sem e com contraste I.V) devem ter início antes da pele
da região glútea e término logo após a pele da região ventral.
                        </p>

                        <img className="img-fluid" src={img4} alt="Reformatacoes" />

                        <p>
                        Para back-up, os cortes coronais Osso devem ter início antes da pele da região glútea e término
logo após a pele da região ventral.
                        </p>


                        <img className="img-fluid" src={img5} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais P.M. filme (sem ou com contraste I.V.) devem ter início 1 cm
posterior ao sacro e término 1 cm após o púbis.
                        </p>

                        <img className="img-fluid" src={img6} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes coronais Osso devem ter início 1 cm posterior ao sacro e término 1 cm após o
púbis.
                        </p>
                        
                        <img className="img-fluid" src={img7} alt="Reformatacoes" />

                        <p>
                        Na reformatação no plano sagital os cortes devem seguir a orientação da esquerda para direita,
utilizar as cabeças femorais como referência para angulação, deixar os cortes perpendiculares,utilizar imagem no plano axial como referência. No plano coronal deixar os cortes perpendiculares
as cabeças femorais, utilizar imagem coronal como referência.
                        </p>

                        <p>
                        Para back-up os cortes sagitais P.M. (sem e com contraste I.V.) devem ter início antes da pele
da região do quadril esquerdo e término logo após o final da pele do quadril direito.
                        </p>

                        <img className="img-fluid" src={img8} alt="Reformatacoes" />

                        <p>
                        Para back-up, os cortes sagitais Osso devem ter início antes da pele da região do quadril esquerdo
e término logo após o final da pele do quadril direito.
                        </p>
                        
                        <img className="img-fluid" src={img9} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais P.M. filme (sem ou com contraste I.V.) devem ter início 0,5 cm
antes do trocanter maior esquerdo e término 0,5 cm após o trocanter maior direito.
                        </p>

                        

                        <img className="img-fluid" src={img10} alt="Reformatacoes" />

                        <p>
                        Para filme, os cortes sagitais Osso devem ter início 0,5 cm antes do trocanter maior esquerdo e
término 0,5 cm após o trocanter maior direito
                        </p>

                        <img className="img-fluid" src={img11} alt="Reformatacoes" />

                        <p>
                        Abaixo algumas opções de reformatações tridimensionais para complementar o estudo da
bacia acordo com a indicação.
                        </p>

                        <img className="img-fluid" src={img12} alt="Reformatacoes" />



                        <ul>
                            <li>3D VR Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                            <li>3D MIP Ósseo é útil em demonstrar anatomia óssea e fraturas.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </Container>
    )
}